import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import { useDispatch, useSelector } from "react-redux";
import ApiClient from "../../methods/api/apiClient";
import { search_success } from "../../actions/search";
import { toast } from "react-toastify";
import moment from "moment";
import environment from "../../environment";
import Select from "react-select";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";

const ManagerListing = () => {
  const navigate = useHistory();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const searchState = useSelector((state) => state.search);
  const [data, setdata] = useState();
  const [filter, setfilter] = useState({
    page: 1,
    count: 20,
    status: "",
    search: "",
    role: "manager",
  });
  const [loading, setloading] = useState(false);
  const [total, settotal] = useState(0);
  const [totalUser, setTotalUser] = useState([]);

  // Create Manager
  const [form, setform] = useState({
    fullName: "",
    teamId: "",
    description: "",
    email: "",
    image: "",
    role: "manager",
  });
  const [filterEmployee, setFilterEmployee] = useState({
    search: "",
    role: "employee",
  });
  const [teams, setteams] = useState();
  const [employeeData, setEmployeeData] = useState([]);

  useEffect(() => {
    getData();
    getTeams();
  }, []);

  const getData = (p = {}) => {
    let filt = { ...filter, ...p, addedBy: user?.id || user?._id };
    setloading(true);
    ApiClient.get(`users/list`, filt).then((res) => {
      if (res.success) {
        setdata(res?.data);
        settotal(res?.total);
      }
      setloading(false);
    });
  };

  useEffect(() => {
    if (user && user.loggedIn) {
      setfilter({ ...filter, search: searchState.data });
      getData({ search: searchState.data, page: 1 });
    }
  }, [searchState]);

  const pageChange = (e) => {
    setfilter({ ...filter, page: e });
    getData({ page: e });
  };

  const statusChange = (item) => {
    let status = "active";
    if (item.status == "active") status = "deactive";
    if (
      window.confirm(
        `Do you want to ${status == "active" ? "activate" : "deactivate"} this`
      )
    ) {
      setloading(true);
      ApiClient.put(
        `change/status?model=users&id=${item?.id}&status=${status}`
      ).then((res) => {
        if (res.success) {
          toast.success(res.message);
          getData();
        }
        setloading(false);
      });
    }
  };

  const handleAction = (id, key) => {
    if (key == "edit") {
      navigate.push(`/manager/edit/${id}`);
    } else {
      if (window.confirm("Do you really want to delete this manager")) {
        ApiClient.delete(`delete?id=${id}&model=users`).then((res) => {
          if (res.success) {
            toast.success(res.message);
            getData();
          }
        });
      }
    }
  };

  const getTotalUser = (p = {}) => {
    setloading(true);
    ApiClient.get(`users/list?addedBy=${user?.id || user?._id}`).then((res) => {
      if (res.success) {
        setTotalUser(res?.data);
      }
      setloading(false);
    });
  };

  useEffect(() => {
    getTotalUser();
  }, []);

  // Create Manager
  const getTeams = () => {
    ApiClient.get(`teams?status=active`).then((res) => {
      if (res.success) {
        setteams(
          res?.data?.map((item) => {
            return { value: item?.id, label: item?.fullName };
          })
        );
      }
    });
  };

  const handleCreateManager = (e) => {
    e.preventDefault();
    let payload = {
      ...form,
      teamId: form?.teamId?.value,
    };

    const name1 = filterEmployee?.search?.split("@");
    let payload2 = {
      role: "employee",
      email: filterEmployee?.search,
      fullName: name1?.length > 0 && name1[0],
    };
    if (totalUser?.length >= user?.numberOfSeats) {
      document.getElementById("CloseManagerModal").click();
      navigate.push("/plan");
    } else {
      loader(true);

      ApiClient.post(`add/user`, payload).then((res) => {
        if (res.success) {
          setTimeout(() => {
            toast.success(res?.message);
          }, 100);
          getData();
          if (filterEmployee?.search) {
            ApiClient.post(`add/user`, {
              ...payload2,
              managerId: res?.data?.id,
            }).then((res) => {
              if (res.success) {
                setFilterEmployee({ ...filterEmployee, search: "" });
              }
            });
          }
          document.getElementById("CloseManagerModal").click();
        }
        loader(false);
      });
    }
  };

  const uploadImage = (e) => {
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("upload/image?modelName=users", {
      file: file,
      modelName: "users",
    }).then((res) => {
      if (res.success) {
        let image = res.data.fullpath;
        setform({ ...form, image: image });
      } else {
        setform({ ...form, image: "" });
      }
      loader(false);
    });
  };

  const clearFields = () => {
    setform({
      fullName: "",
      teamId: "",
      description: "",
      email: "",
      image: "",
      role: "manager",
    });
  };

  const getEmploeeList = (p = {}) => {
    let filt = { ...filterEmployee, ...p, addedBy: user?.id || user?._id };
    setloading(true);
    ApiClient.get(`users/list`, filt).then((res) => {
      if (res.success) {
        setEmployeeData(res?.data);
      }
      setloading(false);
    });
  };

  useEffect(() => {
    getEmploeeList();
  }, []);

  const filterEmploee = (p = {}) => {
    setFilterEmployee({ ...filterEmployee, ...p });
    getEmploeeList({ ...p });
  };

  // ***************************************************************************************

  //   const handleEdit = (id) => {
  //     ApiClient.get(`user/details?id=${id}`).then((res) => {
  //
  //       if (res.success) {
  //         // setdetail(res?.data);
  //         setform({
  //           id: res?.data?.id,
  //           fullName: res?.data?.fullName,
  //           email: res?.data?.email,
  //           teamId: {
  //             value: res?.data?.teamId?.id,
  //             label: res?.data?.teamId?.fullName,
  //           },
  //           image: res?.data?.image,
  //         });
  //         document.getElementById('OpenEmployeeModal').click()
  //       }
  //       // loader(false);
  //     });
  // }

  const handleDelete = (id) => {
    if (window.confirm("Do you really want to delete this manager")) {
      ApiClient.delete(`delete?id=${id}&model=users`).then((res) => {
        if (res.success) {
          toast.success(res.message);
          getData();
        }
      });
    }
  };

  // **************************************************************************************

  return (
    <>
      <Layout>
        <div className="inners_wrapper">
          <div className="d-flex justify-content-end align-items-end my-2">
            <button
              className="btn btn-primary btn-sm"
              onClick={(e) => clearFields()}
              data-toggle="modal"
              data-target="#ManagerModal"
            >
              <i className="fa fa-plus"></i> Add Manager
            </button>
          </div>
          {!loading ? (
            <div className="mvp_table">
              <div className=" table-responsive">
                <table className="table table-hover mt-3">
                  <thead>
                    <tr>
                      <th scope="col">Image</th>
                      {/* <th scope="col"></th> */}
                      <th scope="col">Manager Name</th>
                      <th scope="col">Team</th>
                      <th scope="col">Employee Name</th>
                      <th scope="col">Date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  {loading ? null : (
                    <tbody>
                      {data &&
                        data.map((item) => {
                          return (
                            <tr>
                              <td>
                                <span className="text-capitalize d-flex align-items-center">
                                  <img
                                    src={methodModel.userImg(item?.image)}
                                    className="mr-2 pointer"
                                    onClick={(e) => {
                                      navigate.push(`/manager/${item?.id}`);
                                    }}
                                    width="45px"
                                  />
                                </span>
                              </td>
                              <td>
                                <span className="text-capitalize d-flex align-items-center">
                                  <span className="fs-5 text-capitalize">
                                    {item?.fullName}
                                  </span>
                                </span>
                              </td>
                              <td>{item?.teamName}</td>
                              {/*  */}
                              <td>
                                <span className="fs-5">
                                  {item?.employeeDetails?.fullName || "--"}
                                </span>
                              </td>
                              {/* */}
                              <td>
                                {moment(item?.createdAt).format("DD MMM YYYY")}
                              </td>

                              <td>
                                <i className="fa fa-pen me-2" title="Edit"></i>
                                <i
                                  className="fa fa-trash me-2"
                                  title="Delete"
                                ></i>
                                {/* <span onClick={e => handleEdit(item?.id)}></span>
                                            
                                <span onClick={e => handleDelete(item?.id)}></span> */}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          ) : null}
          {!loading && total == 0 ? (
            <div className="py-3 text-center no-data">
              <img src="/assets/img/no-data-logo.svg" />
              No Data Found
            </div>
          ) : (
            <></>
          )}
          {!loading && total > filter.count ? (
            <div className="paginationWrapper">
              <span>
                Show {filter.count} from {total} records
              </span>
              <Pagination
                currentPage={filter.page}
                totalSize={total}
                sizePerPage={filter.count}
                changeCurrentPage={pageChange}
              />
            </div>
          ) : (
            <></>
          )}
          {loading ? (
            <div className="d-flex justify-content-center py-4">
              <img src="/assets/img/loader.gif" width="40px" />
            </div>
          ) : (
            <></>
          )}
        </div>

        {/* Create Manager Modal */}

        <div
          className="modal fade"
          id="ManagerModal"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ManagerModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-title">
                <div className="p-3 d-flex justify-content-between align-items-start">
                  <div>
                    <h3 className="modal-title " id="ManagerModalLabel">
                      Create Manager
                    </h3>
                    <p className="mb-1">
                      All required fields are marked with an asterisk (
                      <span className="text-danger">*</span>).
                    </p>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <form onSubmit={handleCreateManager}>
                <div className="modal-body">
                  <div className="">
                    <div className="row">
                      <div className="col-md-3 my-2">
                        <label>
                          Name <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-md-9 my-2">
                        <input
                          type="text"
                          value={form?.fullName}
                          onChange={(e) =>
                            setform({ ...form, fullName: e.target.value })
                          }
                          className="form-control"
                          placeholder="Manager Name"
                          required
                        />
                      </div>
                      <div className="col-md-3 my-2">
                        <label>
                          Email <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-md-9 my-2">
                        <input
                          type="email"
                          value={form?.email}
                          onChange={(e) =>
                            setform({ ...form, email: e.target.value })
                          }
                          className="form-control"
                          placeholder="Enter Manager Email"
                          disabled={form?.id}
                          required
                        />
                      </div>
                      <div className="col-md-3 my-2">
                        <label>
                          Team <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-md-9 my-2">
                        <Select
                          value={form?.teamId}
                          isClearable={true}
                          onChange={(e) => setform({ ...form, teamId: e })}
                          options={teams}
                          placeholder="Select Team"
                          isDisabled={form?.id}
                          required
                        />
                      </div>

                      <div className="col-md-3 my-2">
                        <label>
                          Employee<span className="text-danger"></span>
                        </label>
                      </div>
                      <div className="col-md-9 my-2">
                        <input
                          type="email"
                          value={filterEmployee?.search}
                          onChange={(e) => {
                            filterEmploee({ search: e.target.value });
                            setFilterEmployee({
                              ...filterEmployee,
                              search: e.target.value,
                            });
                          }}
                          className="form-control"
                          placeholder="Enter Employee Email"
                          disabled={form?.id}
                        />

                        {employeeData?.length > 0 && filterEmployee?.search && (
                          <div className="location_box">
                            {employeeData?.length > 0 ? (
                              employeeData.map((data, index) => {
                                return (
                                  <div
                                    className="min-height-container"
                                    key={index}
                                    onClick={() => {
                                      setFilterEmployee({
                                        ...filterEmployee,
                                        search: data.email,
                                      });
                                      setEmployeeData([]);
                                    }}
                                  >
                                    {data.email}
                                  </div>
                                );
                              })
                            ) : (
                              <div className="col-12">
                                <div className="text-center text-danger">
                                  <p>There are no data</p>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="col-md-3 my-2">
                        <label>Image</label>
                      </div>
                      <div className="col-md-9 my-2">
                        <div className="maininutcls">
                          <label className="profileImageLabel">
                            {form?.image ? (
                              <img
                                src={`${environment?.api}images/users/${form?.image}`}
                                className="profileImage"
                              />
                            ) : (
                              <img
                                src="/assets/img/placeholder.png"
                                className="profileImage"
                              />
                            )}
                          </label>
                          <div className="profile_btn">
                            <div>
                              <label className="btn btn-primary btn-sm edit ml-3">
                                <input
                                  id="bannerImage"
                                  type="file"
                                  className="d-none"
                                  accept="image/*"
                                  value={form.baseImg ? form.baseImg : ""}
                                  onChange={(e) => {
                                    uploadImage(e);
                                  }}
                                />
                                {form.image ? "Change" : "Upload"} Image
                              </label>
                            </div>
                            <div>
                              {form.image ? (
                                <label
                                  className="btn btn-danger btn-sm  delete ml-3"
                                  onClick={(e) =>
                                    setform({ ...form, image: "" })
                                  }
                                >
                                  Remove Image
                                </label>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="">
                    <button
                      type="submit"
                      disabled={
                        !form?.fullName || !form?.email || !form?.teamId
                      }
                      className="btn btn-primary btn-md mr-2"
                    >
                      Create Manager
                    </button>
                    <button
                      type="button"
                      id="CloseManagerModal"
                      className="btn btn-secondary ml-2"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ManagerListing;
