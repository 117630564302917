import AsyncSelect from "react-select/async";
import ApiClient from "../../../methods/api/apiClient";

const AsyncSelectDropdown = ({
  api = "",
  filter = {},
  placeholder = "",
  value = "",
  onChange = () => {},
  option = (itm) => {
    return {
      label: (
        <div className="d-flex">
          <span className="mr-2 objective-img">
            {/* <LuCircleDotDashed /> */}
            {itm?.keyType == "child_objective" || itm?.keyType == "okr" ? (
              <span className="objective-img ">
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.1 2.18a9.93 9.93 0 0 1 3.8 0"></path>
                  <path d="M17.6 3.71a9.95 9.95 0 0 1 2.69 2.7"></path>
                  <path d="M21.82 10.1a9.93 9.93 0 0 1 0 3.8"></path>
                  <path d="M20.29 17.6a9.95 9.95 0 0 1-2.7 2.69"></path>
                  <path d="M13.9 21.82a9.94 9.94 0 0 1-3.8 0"></path>
                  <path d="M6.4 20.29a9.95 9.95 0 0 1-2.69-2.7"></path>
                  <path d="M2.18 13.9a9.93 9.93 0 0 1 0-3.8"></path>
                  <path d="M3.71 6.4a9.95 9.95 0 0 1 2.7-2.69"></path>
                  <circle cx="12" cy="12" r="1"></circle>
                </svg>
              </span>
            ) : (
              <span className="key-img">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path d="M17 4h3v16h-3V4zM5 14h3v6H5v-6zm6-5h3v11h-3V9z"></path>
                </svg>
              </span>
            )}
          </span>
          <span>{itm.title}</span>
        </div>
      ),
      value: itm._id,
    };
  },
}) => {
  const loadOptions = (inputValue, callback) => {
    ApiClient.get(api, { search: inputValue, ...filter }).then((res) => {
      if (res.success) {
        let arr = res.data.map(option);
        arr.unshift({ value: null, label: "No Parent" });
        if (value) arr.push([value]);
        callback(arr);
      } else {
        callback(value ? [value] : []);
      }
    });
  };

  return (
    <>
      <AsyncSelect
        placeholder={placeholder}
        value={value}
        cacheOptions
        loadOptions={loadOptions}
        onChange={onChange}
        defaultOptions
      />
    </>
  );
};

export default AsyncSelectDropdown;
