import { Formik } from "formik";
import React, { useState } from "react";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router-dom";
import { Link, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import methodModel from "../../methods/methods";
import PaymentModal from "../PaymentModal/PaymentModal";

export default function SignUp() {
  const navigate = useHistory();
  const [form, setForm] = useState({ role: "company", password: "" });
  const [submit, setSubmit] = useState(false);

  const plan_id = methodModel?.getPrams("plan_id");

  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handelSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);

    if (form?.role == "company") {
      if (
        !emailRegex.test(form?.email) ||
        !form?.firstName ||
        !form?.lastName ||
        form?.password?.length < 6
      )
        return;

      // setTimeout(function() {
      // toast.success("You are registered")
      // }, 2000);
    } else {
      if (
        !emailRegex.test(form?.email) ||
        !form?.firstName ||
        !form?.lastName ||
        form?.password?.length < 6
      )
        return;
    }

    loader(true);
    ApiClient.post(`register`, { ...form, planId: plan_id }).then((res) => {
      if (res.success) {
        localStorage.setItem("SignUpEmail", form.email);
        localStorage.setItem("SignUpId", res?.data?.id);
        setTimeout(() => {
          toast.success("Please check your email for verification");
        }, 100);
        navigate.push(`/login`);
      }
      loader(false);
    });
  };

  return (
    <>
      <div className="signIn-main right_hight">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto ">
              <div className="right_side">
                <form className="centerLogin" onSubmit={(e) => handelSubmit(e)}>
                  <div
                    className="logo_set mb-4"
                    onClick={() => navigate.push("/home")}
                  >
                    <img
                      className="logo_login"
                      src="assets/img/images/footer_logo.png"
                    />
                  </div>

                  <div className="text-center mb-4">
                    <h3 className="text-center lgtext">Sign Up</h3>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-center mb-4 justify-content-center">
                      <div className="usertype_selcts mr-3">
                        <div className="form-group mb-0">
                          {/*    */}
                          <input
                            className="radio_input d-none"
                            type="radio"
                            checked={form?.role === "company"}
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            onClick={(e) => {
                              setForm((prev) => ({
                                ...prev,
                                role: "company",
                                firstName: "",
                                lastName: "",
                                email: "",
                                password: "",
                                companyName: "",
                              }));
                              setSubmit(false);
                            }}
                          />
                          <label
                            className="radio_label cursor-pointer d-flex align-items-center"
                            for="flexRadioDefault1"
                          >
                            <p className="ions">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="36"
                                viewBox="0 0 40 36"
                                fill="none"
                              >
                                <path
                                  d="M32 24H28V28H32M32 16H28V20H32M36 32H20V28H24V24H20V20H24V16H20V12H36M16 8H12V4H16M16 16H12V12H16M16 24H12V20H16M16 32H12V28H16M8 8H4V4H8M8 16H4V12H8M8 24H4V20H8M8 32H4V28H8M20 8V0H0V36H40V8H20Z"
                                  fill="black"
                                />
                              </svg>
                            </p>
                            Company
                          </label>
                        </div>
                      </div>
                      <div className="usertype_selcts">
                        <div className="form-group  mb-0">
                          <input
                            className="radio_input d-none"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked={form?.role === "individual"}
                            onClick={(e) => {
                              setForm({
                                ...form,
                                role: "individual",
                                password: "",
                                email: "",
                                firstName: "",
                                lastName: "",
                              });

                              setSubmit(false);
                            }}
                          />
                          <label
                            className="radio_label cursor-pointer d-flex align-items-center"
                            for="flexRadioDefault2"
                          >
                            <p className="ions">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="42"
                                viewBox="0 0 22 42"
                                fill="none"
                              >
                                <path
                                  d="M10.9087 15.267C15.1425 15.267 18.5759 11.844 18.5759 7.623C18.5759 3.423 15.1425 0 10.9087 0C6.67486 0 3.26253 3.423 3.26253 7.623C3.26253 11.844 6.67486 15.267 10.9087 15.267ZM5.45316 23.037C5.45316 23.037 3.15722 29.274 2.50424 30.45C2.27254 30.891 2.31467 31.017 1.74595 31.017C1.17723 31.017 0.292551 30.45 0.292551 30.45C0.292551 30.45 -0.128723 30.177 0.039787 29.694C0.545316 28.14 2.84126 19.068 3.32573 17.325C3.85232 15.456 5.28465 15.519 5.28465 15.519H6.65379L11.0772 23.184L15.5216 15.519H17.1646C17.1646 15.519 17.8176 15.603 18.302 16.17C18.8076 16.737 18.9129 17.724 18.9129 17.724L21.8407 29.064C21.8407 29.064 22.0514 29.862 21.9882 30.093C21.9039 30.45 21.7143 30.45 21.7143 30.45C21.7143 30.45 20.8929 30.702 20.4295 30.849C19.5869 31.122 19.4395 30.324 19.4395 30.324L16.7012 23.184L16.3852 42H12.2778L11.5827 31.269C11.5827 31.269 11.4563 30.996 11.0772 30.996C10.7191 30.996 10.5927 31.269 10.5927 31.269L9.85548 42H5.74805L5.47423 23.037H5.45316Z"
                                  fill="black"
                                />
                              </svg>
                            </p>
                            Individual
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {/* {form?.role === "company" && <div className='col-12 col-md-12'>
                  <div className='form-group'>
                    <input type='text' value={form?.companyName} onChange={(e) => setForm({ ...form, companyName: e.target.value })} placeholder='Company Name' className='form-control bginput' />
                    {submit && !form?.companyName && <p className='text-danger'>Company name is required</p>}
                  </div>
                </div>} */}

                    <div className="col-md-6 ">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          type="text"
                          value={form?.firstName}
                          placeholder="First Name"
                          onChange={(e) =>
                            setForm({ ...form, firstName: e.target.value })
                          }
                          className="form-control bginput"
                        />
                        {submit && !form?.firstName && (
                          <p className="text-danger">First name is required</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 ">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          value={form?.lastName}
                          onChange={(e) =>
                            setForm({ ...form, lastName: e.target.value })
                          }
                          placeholder="Last Name"
                          className="form-control bginput"
                        />
                        {submit && !form?.lastName && (
                          <p className="text-danger">Last name is required</p>
                        )}
                      </div>
                    </div>

                    {form.role == "individual" ? (
                      <></>
                    ) : (
                      <div className="col-md-12 ">
                        <div className="form-group">
                          <label>Company Name</label>
                          <input
                            type="text"
                            value={form?.companyName}
                            onChange={(e) =>
                              setForm({ ...form, companyName: e.target.value })
                            }
                            placeholder="Company Name"
                            className="form-control bginput"
                          />
                          {submit && !form?.companyName}
                        </div>
                      </div>
                    )}

                    <div className="col-12 col-md-12">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          type="email"
                          value={form?.email}
                          onChange={(e) =>
                            setForm({ ...form, email: e.target.value })
                          }
                          placeholder="Email Address"
                          className="form-control bginput"
                        />
                        {submit && !emailRegex.test(form?.email) && (
                          <p className="text-danger">Enter valid email</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 ">
                      <div className="form-group">
                        <div>
                          <div className="number_dail">
                            <label>Contact Number</label>
                            <div className="inner_dial">
                              <PhoneInput
                                country={"fr"}
                                value={form?.dialCode}
                                className="input_number"
                                onChange={(phone) => {
                                  setForm({ ...form, dialCode: phone });
                                }}
                              />

                              <input
                                type="text"
                                name="mobileNo"
                                className="dail"
                                value={form && form?.mobileNo}
                                maxLength={12}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    mobileNo: methodModel.isNumber(e),
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label>Password</label>
                      <div className="form-group position-relative mb-0">
                        <input
                          className="form-control "
                          type={!eyes?.password ? "password" : "text"}
                          value={form?.password}
                          onChange={(e) =>
                            setForm({ ...form, password: e.target.value })
                          }
                          placeholder="Password"
                        />
                        <span className="eye_right">
                          <i
                            className={
                              eyes.password ? "fa fa-eye" : "fa fa-eye-slash"
                            }
                            onClick={() =>
                              setEyes({ ...eyes, password: !eyes?.password })
                            }
                          ></i>
                        </span>
                      </div>
                      {submit && form?.password?.length < 6 && (
                        <p className="text-danger">Password must 8 digit</p>
                      )}
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary loginclass mt-3"
                      >
                        Sign Up
                      </button>
                      <p className="text-center mt-3 no-account">
                        Already have an account?{" "}
                        <span className="d-block">
                          <Link className="sign_up" to="/login">
                            {" "}
                            Log In{" "}
                            <i
                              className="fa fa-arrow-right"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </span>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
}
