import { useState, useEffect } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import "./profile.scss";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ChangePassword from "../../components/Profile/ChangePassword";
import EditProfile from "../../components/Profile/Edit";
import environment from "../../environment";

const Profile = () => {
  const user = useSelector((state) => state.user);
  const [data, setData] = useState("");
  const history = useHistory();

  const gallaryData = () => {
    loader(true);
    ApiClient.get(`profile`, { id: user.id }).then((res) => {
      if (res.success) {
        setData(res.data);
      }
      loader(false);
    });
  };

  useEffect(() => {
    if (user && user.loggedIn) {
      gallaryData();

      let tab = methodModel.getPrams("tab");
      if (tab == "change-pasword") {
        document.getElementById("v-pills-profile-tab")?.click();
      }
    }
  }, []);

  const [ShowWhich, setShowWhich] = useState("profile");
  return (
    <Layout>
      <div className="profile-tabs">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 p-0">
              <div className=" profile-tabs-left">
                <div
                  className="nav flex-column nav-pills "
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button
                    className="nav-link active"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                    onClick={(e) => setShowWhich("profile")}
                  >
                    <i
                      className="fa fa-user-circle me-2"
                      aria-hidden="true"
                    ></i>
                    Profile
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                    onClick={(e) => setShowWhich("changepassword")}
                  >
                    <i className="fa fa-lock me-2" aria-hidden="true"></i>
                    Change Password
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-9 p-0">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  {ShowWhich == "profile" ? (
                    <div className="pprofile1">
                      <div className="d-flex justify-content-end  mb-3">
                        <p
                          className="btn btn-primary edit-profiles"
                          onClick={(e) => setShowWhich("edit")}
                        >
                          <i
                            className="material-icons prob"
                            title="Edit Profile"
                          >
                            mode_edit_outline
                          </i>

                          {/* <i className="fa fa-edit" title='Edit Profile' /> */}
                        </p>
                      </div>
                      <div className="form-row">
                        <div className="col-md-12 mb-3 inputFlex">
                          <label>Image</label>
                          <div>
                            <label className="profileImageLabel">
                              <img
                                src={
                                  data?.image
                                    ? `${environment.api}/images/users/` +
                                      data?.image
                                    : "/assets/img/person.jpg"
                                }
                                className="profileImage"
                              />
                            </label>
                          </div>
                        </div>

                        {(data?.role == "individual" || data?.isEmployee) && (
                          <div className="col-md-12 inputFlex mb-3">
                            <label>Name</label>
                            <div>
                              <p className="profile_data">
                                {data &&
                                  methodModel.firtLetterCap(
                                    data.fullName || data.firstName
                                  )}
                              </p>
                            </div>
                          </div>
                        )}

                        {data?.role == "company" && (
                          <div className="col-md-12 inputFlex mb-3">
                            <label>Company Name</label>
                            <div>
                              <p className="profile_data">
                                {data &&
                                  methodModel.firtLetterCap(data?.companyName)}
                              </p>
                            </div>
                          </div>
                        )}

                        <div className="col-md-12 inputFlex mb-3">
                          <label>Role</label>
                          <div>
                            <p className="profile_data">
                              {data && methodModel.firtLetterCap(data.role)}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-12 inputFlex mb-3">
                          <label>Email</label>
                          <div>
                            <p className="profile_data">{data && data.email}</p>
                          </div>
                        </div>

                        {data.mobileNo ? (
                          <div className="col-md-12 inputFlex mb-3">
                            <label>Mobile No</label>
                            <div>
                              <p className="profile_data">
                                {data && data.dialCode + data.mobileNo}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {data.address ? (
                          <div className="col-md-12 inputFlex mb-3">
                            <label>Mobile No</label>
                            <div>
                              <p className="profile_data">
                                {data &&
                                  methodModel.firtLetterCap(data.address)}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {/* <div className="col-md-12">
            <label>Created At</label>
            <p className="bg-light rounded px-3 py-2">{data && data.createdAt}</p>
          </div> */}
                      </div>
                    </div>
                  ) : (
                    <EditProfile Layout={false} />
                  )}
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                >
                  <div>
                    <ChangePassword />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
