import React, { useEffect, useState } from "react";
import methodModel from "../../methods/methods";
import ApiClient from "../../methods/api/apiClient";
import { Tooltip } from "antd";
import environment from "../../environment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import loader from "../../methods/loader";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { GoIssueTrackedBy } from "react-icons/go";
import { GoIssueTracks } from "react-icons/go";
import { CgDanger } from "react-icons/cg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ChildModalOKR = ({
  valueData,
  setNewValue,
  valueDataUnit,
  setNewValueUnit,
  detail,
  handleAction,
  findOKRProgress,
  keyResult,
  setkeyResult,
  seteditor,
  editor,
  owners,
  updateKeyResult,
  newupdated,
  getKeyResults,
  setupdateKeyResult,
  clearFields,
  childObjective,
  form,
  setform,
  getData,
  sessions,
  parentKeyResult,
  keyResultData,
  setChildObjective,
  setkeyResultData,
  setparentKeyResult,
  total,
  detailModal,
}) => {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const [OKRFilter, setOKRFilter] = useState("keyResult");
  const [childKeyResult, setchildKeyResult] = useState("");
  const [disableed, setdisable] = useState(false);
  const [keyChildResultData, setkeyChildResultData] = useState([]);
  const [filter, setfilter] = useState({
    page: 1,
    count: 10,
    search: "",
    ownerId: "",
  });
  const [summit, setsummit] = useState(false);
  const [data, setdata] = useState([]);
  const [details, setdetail] = useState();

  const handleCreateOKR = (e) => {
    e.preventDefault();
    setdisable(true);
    loader(true);
    let payload = {
      title: form?.title,
      ownerIds:
        form?.ownerIds?.length > 0
          ? form.ownerIds.map((item) => item?.value)
          : [],
      session: form?.session?.value,

      sessionId: form?.session?.Id,
      parentId: form?.parentId?.value,
      okrId: form?.parentId?.value,
      keyType: form?.parentId?.value ? "child_objective" : "okr",
      grandParentId: detail?.id || detail?._id,
    };
    if (detail?.id && !childObjective?._id) {
      payload = { ...payload, id: detail?.id };
      ApiClient.put(`okr`, payload).then((res) => {
        if (res.success) {
          toast.success(res?.message);
          getData({}, false);
          document.getElementById("CloseOKRsChildModal").click();
          setdisable(false);
        }
      });
    } else {
      ApiClient.post(`okr`, payload).then((res) => {
        if (res.success) {
          if (form?.parentId?.value) {
            toast.success("Child OKR Created");
          } else {
            // toast.success(res?.message);
            toast.success("Objective created! Let's crush it! 💪");
          }
          getData({}, false);
          if (form?.parentId?.value && childObjective?._id) {
            getKeyResults();
          }
          document.getElementById("CloseOKRsChildModal").click();
          setdisable(false);
        }
        loader(false);
      });
    }
  };
  const handleUpdateKeyResultModal = (item) => {
    setupdateKeyResult({
      ...updateKeyResult,
      goal: item?.targetNumber,
      currentValue: item?.initialNumber,
      newValue: item?.newValue || item?.initialNumber,
      keyResultId: item?._id || item?.id,
      confidenceLevel: item?.confidenceLevel,
      note: item?.description,
      dateOfUpdate: new Date(item?.dateOfUpdate || new Date()),
      unit: item?.unit,
    });
  };

  const handleKeyResultAction = (type, id) => {
    if (type == "Delete") {
      if (window.confirm(`Do you really want to delete this key result`)) {
        ApiClient.delete(`okr?id=${id}`).then((res) => {
          if (res.success) {
            getKeyResults();
            getData({}, false);
          }
        });
      }
    } else if (type == "Child Objective") {
      getKeyResults(id?.okrId);
      setChildObjective(id);
      setform({
        ...form,
        parentId: { value: id?._id || id?.id, label: id?.title },
      });
      document.getElementById("CreateOKRModalChildOpen").click();
    } else if (type == "Child Key Result") {
      document.getElementById("OpenKeyResultChildModal").click();
      setchildKeyResult(id?._id || id?.id);
    } else {
      setkeyResult({
        ...id,
        ownerIds: id?.ownerDetails?.map((item) => {
          return { value: item?.id, label: item?.name };
        }),
        // softDeadline: new Date(id?.softDeadline),
        // hardDeadline: new Date(id?.hardDeadline),
      });
      document.getElementById("OpenKeyResultChildModal").click();
    }
  };

  const clearKeyResultModal = () => {
    setkeyResult({
      title: "",
      unit: "not set",
      description: "",
      targetNumber: "",
      initialNumber: "",
      description: "",
      ownerIds: [],
      deadline: "no custom deadline",
      softDeadline: "",
      hardDeadline: "",
    });
    seteditor({ ...editor, keyResult: "", noteKeyResult: "" });
  };

  const handleCreateKeyResult = (e) => {
    e.preventDefault();
    loader(true);
    let payload = {
      ...keyResult,
      description: editor?.keyResult,
      ownerIds: keyResult?.ownerIds?.map((item) => item?.value) || [],
      okrId: detail?.id || detail?._id,
      session: form?.session?.value,
      keyType: "key_result",
    };
    payload.targetNumber = Number(payload?.targetNumber);
    payload.initialNumber = Number(payload?.initialNumber);
    if ((keyResult?._id || keyResult?.id) && !childKeyResult) {
      payload = { ...payload, id: keyResult?._id || keyResult?.id };
      ApiClient.put(`okr`, payload).then((res) => {
        if (res.success) {
          getKeyResults(res?.updateOkr?.okrId);
          document.getElementById("CloseKeyResultChildModal").click();
          getData({}, false);
          detailModal(detail?.id || detail?._id, false);
          // handleAction('Edit',detail?.id || detail?._id)
          setOKRFilter("keyResult");
        }
        loader(false);
      });
    } else {
      payload = {
        ...payload,
        keyType: "key_result",
        parentId: childKeyResult || detail?.id || detail?._id || null,
        // keyType: "key_result",
        initialNumber: payload?.initialNumber || 0,
        newValue: payload?.initialNumber || 0,
      };

      // if(payload.targetNumber < payload.initialNumber ){
      //   setsummit(true)
      //    return
      // }
      ApiClient.post(`okr`, payload).then((res) => {
        if (res.success) {
          getKeyResults(res?.data?.okrId);
          getData({}, false);
          setOKRFilter("keyResult");
          detailModal(detail?.id || detail?._id, false);
          toast("New Key Result added! Let's measure success together! 📊", {
            position: "bottom-right",
            autoClose: 4000,
          });
          document.getElementById("CloseKeyResultChildModal").click();
        }
        loader(false);
      });
    }
  };

  const handleUpdateKeyResult = (e) => {
    e.preventDefault();
    let payload = {
      newValue: updateKeyResult?.newValue,
      confidenceLevel: updateKeyResult?.confidenceLevel,
      note: editor?.noteKeyResult,
      dateOfUpdate: updateKeyResult?.dateOfUpdate,
      okrId: updateKeyResult?.okrId,
      id: updateKeyResult?.keyResultId,
      goal: updateKeyResult?.goal,
    };

    // if(payload.newValue > payload.goal)
    // {
    //   toast(`You can increase upto ${payload.goal}`)

    // }
    // else
    // {
    ApiClient.put(`okr`, payload).then((res) => {
      if (res.success) {
        getKeyResults(updateKeyResult?.okrId);
        getData({}, false);
        detailModal(detail?.id || detail?._id, false);
        // handleAction('Edit',detail?.id || detail?._id)
        document.getElementById("CloseUpdateKeyResultChildModal").click();
        // toast(payload?.newValue > updateKeyResult?.goal?"Your limit only 100":null )

        toast(
          payload?.newValue == updateKeyResult?.goal
            ? "Victory! You've nailed a Key Result. 🏆🚀"
            : "Another step closer to success! 👣",
          { position: "bottom-right", autoClose: 4000 }
        );
        //   toast(
        //     (payload?.newValue == updateKeyResult?.goal
        //         ? "Victory! You've nailed a Key Result. 🏆🚀"
        //         : (payload?.newValue > updateKeyResult?.goal
        //             ? "Your limit only 100"
        //             : "Another step closer to success! 👣")
        //     ),
        //     {
        //         position: "bottom-right",
        //         autoClose: 4000,
        //     }

        // );
      }
    });

    // }
  };

  // const hnadleChildData=()=>{
  //   ApiClient.get(`okrs?okrId=${updateKeyResult?.okrId}&keyType=child_objective`).then(
  //           (res) => {
  //             if (res.success) {
  //               setkeyChildResultData(res?.data);
  // setparentKeyResult(
  //   res?.data?.map((item) => {
  //     return { value: item?._id, label: item?.title };
  //   })
  // );
  //           }
  //           loader(false);
  // })}

  useEffect(() => {
    if (updateKeyResult?.okrId) {
      // hnadleChildData()
    }
  }, [updateKeyResult?.okrId]);

  useEffect(() => {
    {
      getChildData();
    }
  }, []);

  // const handleKeyResultandChildOKR = (e) => {
  //   if (e == "keyResult") {
  //     loader(true);
  //     ApiClient.get(
  //       `okrs?okrId=${detail?.id ? detail?.id : detail?._id}&addedBy=${
  //         user?.id
  //       }`
  //     ).then((res) => {
  //       if (res.success) {
  //         setkeyResultData(res?.data);
  //         setparentKeyResult(
  //           res?.data?.map((item) => {
  //             return { value: item?._id, label: item?.title };
  //           })
  //         );
  //       }
  //       loader(false);
  //     });
  //   } else {
  //     loader(true);
  //     ApiClient.get(`okrs?sessionId=${id}&keyType=child_objective`).then(
  //       (res) => {
  //         if (res.success) {
  //           setkeyResultData(res?.data);
  //           setparentKeyResult(
  //             res?.data?.map((item) => {
  //               return { value: item?._id, label: item?.title };
  //             })
  //           );
  //         }
  //         loader(false);
  //       }
  //     );
  //   }
  //   setOKRFilter(e);
  // };

  const findUnitSymbol = (item) => {
    if (item?.unit == "%") return "";
    else {
      if (!item?.unit || item?.unit == "not set") return "";
      else return item?.unit;
    }
  };

  const getChildData = (p = {}, load = true) => {
    let filt = {
      ...filter,

      // sessionId: sessionId || getsessionId,keyType:"okr",
      ...p,
    };
    if (user?.role != "employee") {
      filt = { ...filt, addedBy: user?.id || user?._id };
    }

    loader(true);
    // setLoadingState(true);
    ApiClient.get(`okrs`, filt).then((res) => {
      // setLoadingState(false);
      if (res.success) {
        setdata([...res?.data]);

        // settotal(res?.total);
        // setchild(res?.data.childObjective);
        if (detail?.id || detail?._id) {
          setdetail(
            res?.data?.find(
              (item) => item?._id == detail?.id || item?._id == detail?._id
            )
          );
        }
      }
      loader(false);
    });
  };

  return (
    <>
      {/* Full Side Screen Modal */}
      <button
        className="btn btn-primary d-none"
        id="OpenFullSideScreenChildModal"
        data-toggle="modal"
        data-target="#fullSideScreenChildModal"
      >
        OKR Detail
      </button>
      <div
        className="modal fade drawer right-align"
        id="fullSideScreenChildModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="fullSideScreenChildModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content fullSideScreenChildModal modal-lg">
            <div className="modal-header align-items-center">
              <h5 className="modal-title" id="fullSideScreenChildModalLabel">
                {detail?.title}
              </h5>
              <div className="d-flex">
                <div className="dropdowns_custom">
                  {user?.role != "employee" && (
                    <div className="dropdown">
                      <span
                        className="text-dark p-2"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i
                          className="fa fa-ellipsis-h text-dark"
                          aria-hidden="true"
                        ></i>
                      </span>
                      <div className="dropdown-menu">
                        <span
                          className="dropdown-item text-danger pointer"
                          onClick={(e) => handleAction("Delete", detail?.id)}
                        >
                          Delete OKR
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  id="CloseFullSideScreenChildModal"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="row ml-2">
                <div className="col-md-3 my-2">
                  <label className="">
                    Session <span className="text-danger">*</span>
                  </label>
                </div>
                <div className="col-md-9 my-2">
                  <label className="text-capitalize">
                    {detail?.sessionTitle?.title || detail?.sessionTitle}
                  </label>
                </div>
                <div className="col-md-3 my-2">
                  <label className="">
                    Owner<span className="text-danger">*</span>
                  </label>
                </div>
                <div className="col-md-9 my-2">
                  <label className="">
                    {detail?.ownerDetails?.map((item) => {
                      return (
                        <div className="ui-chip">
                          <span className="ui-chip-icon ui-chip-icon-bg">
                            <img
                              src={methodModel.userImg(item?.image)}
                              width=""
                            />
                          </span>
                          <p className="mb-0">{item?.fullName || item?.name}</p>
                        </div>
                      );
                    })}
                  </label>
                </div>
                <div className="col-md-12 my-2">
                  <div className="d-flex w-100 align-items-center justify-content-between">
                    <div>
                      <label className="">Progress</label>
                      {user?.role != "employee" && (
                        <button
                          className="btn key-btn ms-5"
                          id="OpenKeyResultChildModal"
                          onClick={() => {
                            setchildKeyResult("");
                          }}
                          data-toggle="modal"
                          data-target="#KeyResultChildModal"
                        >
                          <i className="fa fa-plus me-2"></i>Add Key Result
                        </button>
                      )}
                    </div>
                    <span className="progress-as-text d-flex">
                      {/* <select
                        className="form-select mr-3"
                        value={OKRFilter}
                        onChange={(e) =>
                          handleKeyResultandChildOKR(e.target.value)
                        }
                        aria-label="Default select example"
                      >
                        <option value="keyResult">Key Results</option>
                        <option value="childObjective">Child Objectives</option>
                      </select> */}

                      {findOKRProgress(
                        detail?.keyResults,
                        "okr from child modal"
                      )}
                    </span>
                  </div>
                </div>

                <div className="col-md-12">
                  <table className="table key-result-table">
                    <tbody>
                      {keyResultData &&
                        keyResultData?.map((item) => {
                          return (
                            <>
                              <tr>
                                <td scope="row" className="result-title">
                                  <p className="mb-0">{item?.title}</p>
                                  {OKRFilter == "keyResult" ? (
                                    <span className="small-label">
                                      Should move to {findUnitSymbol()}
                                      {item?.targetNumber}
                                      {item?.unit == "%" ? "%" : ""}, current
                                      progress at {findUnitSymbol(item)}
                                      {item?.newValue || item?.initialNumber}
                                      {item?.unit == "%" ? "%" : ""}
                                    </span>
                                  ) : null}
                                </td>
                                {OKRFilter == "keyResult" ? (
                                  <td>
                                    <span
                                      className={`p-2 badges-color ${
                                        item?.confidenceLevel == "low" ||
                                        item?.confidenceLevel == ""
                                          ? "red-color"
                                          : item?.confidenceLevel == "medium"
                                          ? "orange-color"
                                          : "green-color"
                                      } text-capitalize`}
                                    >
                                      {item?.confidenceLevel == "low" ? (
                                        <span className="badges-diff">
                                          <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 24 24"
                                            className="me-1"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M2.5 12a9.5 9.5 0 1 1 19 0 .75.75 0 0 0 1.5 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11a.75.75 0 0 0 0-1.5A9.5 9.5 0 0 1 2.5 12Z"></path>
                                            <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2.5 2.75a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm3.75 2.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"></path>
                                          </svg>
                                          OFF TRACK{" "}
                                        </span>
                                      ) : item?.confidenceLevel == "medium" ? (
                                        <span className="badges-diff">
                                          <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth="0"
                                            viewBox="0 0 24 24"
                                            className="me-1"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z"
                                              fill="currentColor"
                                            ></path>
                                            <path
                                              d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z"
                                              fill="currentColor"
                                            ></path>
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
                                              fill="currentColor"
                                            ></path>
                                          </svg>
                                          AT RISK
                                        </span>
                                      ) : item?.confidenceLevel == "high" ? (
                                        <span className="badges-diff">
                                          <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 24 24"
                                            className="me-1"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M12 2.5a9.5 9.5 0 1 0 0 19 .75.75 0 0 1 0 1.5C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11a.75.75 0 0 1-1.5 0A9.5 9.5 0 0 0 12 2.5Z"></path>
                                            <path d="m13.759 17.48 3.728 3.314a.308.308 0 0 0 .513-.23V18h4.25a.75.75 0 0 0 0-1.5H18v-2.564a.308.308 0 0 0-.513-.23L13.76 17.02a.308.308 0 0 0 0 .46ZM12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
                                          </svg>
                                          ON TRACK
                                        </span>
                                      ) : (
                                        <span className="badges-diff">
                                          <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 24 24"
                                            className="me-1"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M2.5 12a9.5 9.5 0 1 1 19 0 .75.75 0 0 0 1.5 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11a.75.75 0 0 0 0-1.5A9.5 9.5 0 0 1 2.5 12Z"></path>
                                            <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2.5 2.75a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm3.75 2.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"></path>
                                          </svg>
                                          OFF TRACK
                                        </span>
                                      )}
                                    </span>
                                  </td>
                                ) : null}
                                <td>
                                  <span className="img-width-result">
                                    {item?.ownerDetails?.map((itm) => {
                                      return (
                                        <>
                                          <Tooltip
                                            title={itm?.name}
                                            placement="top"
                                          >
                                            <img
                                              src={methodModel.userImg(
                                                itm?.image
                                              )}
                                              width="25px"
                                            />
                                          </Tooltip>
                                        </>
                                      );
                                    })}
                                  </span>
                                </td>
                                <td>
                                  <div className="progreebar-main">
                                    <div className="progress">
                                      <div
                                        className="progress-bar progress-bar-striped"
                                        role="progressbar"
                                        style={{
                                          width: `${
                                            Math.abs(
                                              (item?.newValue -
                                                item?.initialNumber) /
                                                (item?.targetNumber -
                                                  item?.initialNumber)
                                            ) * 100
                                          }%`,
                                        }}
                                        aria-valuenow="10"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                    <span className="mb-0 mr-3">
                                      {item?.initialNumber == 0 &&
                                      item?.targetNumber == 0
                                        ? "0"
                                        : Math.abs(
                                            ((item?.newValue -
                                              item?.initialNumber) /
                                              (item?.targetNumber -
                                                item?.initialNumber)) *
                                              100
                                          ).toFixed(0) || "100"}
                                      %
                                    </span>
                                  </div>
                                </td>
                                {OKRFilter == "keyResult" ? (
                                  <td>
                                    {user?.role != "employee" && (
                                      <div className="text-center">
                                        <button
                                          id="UpdateKeyResultChildModal201"
                                          className="btn key-btn update-btn text-center"
                                          onClick={(e) => {
                                            setNewValue(
                                              item?.newValue ||
                                                item?.initialNumber
                                            );
                                            setNewValueUnit(item?.unit);
                                            handleUpdateKeyResultModal(item);
                                          }}
                                          data-toggle="modal"
                                          data-target="#UpdateKeyResultChildModal"
                                        >
                                          Update
                                        </button>
                                      </div>
                                    )}
                                  </td>
                                ) : null}
                                <td>
                                  {user?.role != "employee" && (
                                    <div className="dropdowns_custom ">
                                      <div className="dropdown">
                                        <span
                                          className="text-dark"
                                          type="button"
                                          data-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i
                                            className="fa fa-ellipsis-h text-dark"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                        <div className="dropdown-menu">
                                          {OKRFilter == "keyResult" ? (
                                            <span
                                              className="dropdown-item pointer"
                                              onClick={(e) =>
                                                handleKeyResultAction(
                                                  "Child Objective",
                                                  item
                                                )
                                              }
                                            >
                                              Add Child Objective
                                            </span>
                                          ) : (
                                            <span
                                              className="dropdown-item pointer"
                                              onClick={(e) =>
                                                handleKeyResultAction(
                                                  "Child Key Result",
                                                  item
                                                )
                                              }
                                            >
                                              Add Key Result
                                            </span>
                                          )}
                                          {OKRFilter == "keyResult" ? (
                                            <span
                                              className="dropdown-item pointer"
                                              onClick={(e) => {
                                                handleKeyResultAction(
                                                  "Edit",
                                                  item
                                                );
                                                setchildKeyResult("");
                                              }}
                                            >
                                              Edit
                                            </span>
                                          ) : null}
                                          <span
                                            className="dropdown-item text-danger pointer"
                                            onClick={(e) =>
                                              handleKeyResultAction(
                                                "Delete",
                                                item?._id || item?.id
                                              )
                                            }
                                          >
                                            Delete
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <p>Child objectives</p> */}

              {/* <div className="col-md-12">
                  <table className="table key-result-table">
                    <tbody>
                       {detail &&
                        detail?.allChildFromGp?.map((item) => {
                          return ( 
                            <>
                              <tr>
                                <td scope="row" className="result-title">
                                  <p className="mb-0">{item?.title}</p>
                                  {OKRFilter == "keyResult" ? (
                                    <span className="small-label">
                                      Should move to {findUnitSymbol()}
                                      {item?.targetNumber}
                                      {item?.unit == "%" ? "%" : ""}, current
                                      progress at {findUnitSymbol(item)}
                                      {item?.newValue || item?.initialNumber}
                                      {item?.unit == "%" ? "%" : ""}
                                    </span>
                                  ) : null}
                                </td>
                                { (
                                  <td>
                                    
                                      <span
                                        className={`p-2 badges-color ${
                                          (item?.confidenceLevel == "low" || item?.confidenceLevel=="")
                                            ? "red-color"
                                            : item?.confidenceLevel == "medium"
                                            ? "orange-color"
                                            : "green-color"
                                        } text-capitalize`}
                                      >
                                        {item?.confidenceLevel == "low" ? (
                                          <span className="badges-diff">
                                            <svg
                                              stroke="currentColor"
                                              fill="currentColor"
                                              strokeWidth="0"
                                              viewBox="0 0 24 24"
                                              className="me-1"
                                              height="1em"
                                              width="1em"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path d="M2.5 12a9.5 9.5 0 1 1 19 0 .75.75 0 0 0 1.5 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11a.75.75 0 0 0 0-1.5A9.5 9.5 0 0 1 2.5 12Z"></path>
                                              <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2.5 2.75a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm3.75 2.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"></path>
                                            </svg>
                                            OFF TRACK{" "}
                                          </span>
                                        ) : item?.confidenceLevel ==
                                          "medium" ? (
                                            <span className="badges-diff">
                                            <svg
                                              stroke="currentColor"
                                              fill="none"
                                              strokeWidth="0"
                                              viewBox="0 0 24 24"
                                              className="me-1"
                                              height="1em"
                                              width="1em"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z"
                                                fill="currentColor"
                                              ></path>
                                              <path
                                                d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z"
                                                fill="currentColor"
                                              ></path>
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
                                                fill="currentColor"
                                              ></path>
                                            </svg>
                                            AT RISK
                                          </span>
                                        ) : item?.confidenceLevel == "high" ? (
                                          <span className="badges-diff">
                                            <svg
                                              stroke="currentColor"
                                              fill="currentColor"
                                              strokeWidth="0"
                                              viewBox="0 0 24 24"
                                              className="me-1"
                                              height="1em"
                                              width="1em"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path d="M12 2.5a9.5 9.5 0 1 0 0 19 .75.75 0 0 1 0 1.5C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11a.75.75 0 0 1-1.5 0A9.5 9.5 0 0 0 12 2.5Z"></path>
                                              <path d="m13.759 17.48 3.728 3.314a.308.308 0 0 0 .513-.23V18h4.25a.75.75 0 0 0 0-1.5H18v-2.564a.308.308 0 0 0-.513-.23L13.76 17.02a.308.308 0 0 0 0 .46ZM12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
                                            </svg>
                                            ON TRACK
                                          </span>
                                        ) : (
                                          <span className="badges-diff">
                                            <svg
                                              stroke="currentColor"
                                              fill="currentColor"
                                              strokeWidth="0"
                                              viewBox="0 0 24 24"
                                              className="me-1"
                                              height="1em"
                                              width="1em"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path d="M2.5 12a9.5 9.5 0 1 1 19 0 .75.75 0 0 0 1.5 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11a.75.75 0 0 0 0-1.5A9.5 9.5 0 0 1 2.5 12Z"></path>
                                              <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2.5 2.75a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm3.75 2.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"></path>
                                            </svg>
                                            OFF TRACK
                                          </span>
                                        )}
                                      </span>
                                   
                                  </td>
                                ) }
                                <td>
                                  <span className="img-width-result">
                                    {item?.ownerDetails?.map((itm) => {
                                      return (
                                        <>
                                          <Tooltip
                                            title={itm?.name}
                                            placement="top"
                                          >
                                            <img
                                              src={methodModel.userImg(
                                                itm?.image
                                              )}
                                              width="25px"
                                            />
                                          </Tooltip>
                                        </>
                                      );
                                    })}
                                  </span>
                                </td>
                                <td>
                                  <div className="progreebar-main">
                                    <div className="progress">
                                      <div
                                        className="progress-bar progress-bar-striped"
                                        role="progressbar"
                                        style={{
                                          width: `${
                                            Math.abs(((item?.newValue -
                                              item?.initialNumber)) /
                                              (item?.targetNumber-item?.initialNumber)) *
                                            100
                                          }%`,
                                        }}
                                        aria-valuenow="10"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                    <span className="mb-0 mr-3">
                                      {item?.initialNumber == 0 &&
                                      item?.targetNumber == 0
                                        ? "0"
                                        : Math.abs(
                                            ((item?.newValue -
                                              item?.initialNumber) /
                                              (item?.targetNumber-item?.initialNumber)) *
                                              100
                                          ).toFixed(0)}
                                      %
                                    </span>
                                  </div>
                                </td>
                                {OKRFilter == "keyResult" ? (
                                  <td>
                                    <div className="text-center">
                                      <button
                                      id="Child"
                                        className="btn key-btn update-btn text-center"
                                        onClick={(e) =>{
                                          setNewValue(item?.newValue || item?.initialNumber)
                                          setNewValueUnit(item?.unit)
                                          handleUpdateKeyResultModal(item)}
                                        }
                                        data-toggle="modal"
                                        data-target="#UpdateKeyResultModal"
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </td>
                                ) : null}
                                <td>
                                  <div className="dropdowns_custom ">
                                    <div className="dropdown">
                                      <span
                                        className="text-dark"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="fa fa-ellipsis-h text-dark"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <div className="dropdown-menu">
                                        {OKRFilter == "keyResult" ? (
                                          <span
                                            className="dropdown-item pointer"
                                            onClick={(e) =>
                                              handleKeyResultAction(
                                                "Child Objective",
                                                item
                                              )
                                            }
                                          >
                                            Add Child Objective
                                          </span>
                                        ) : (
                                          <span
                                            className="dropdown-item pointer"
                                            onClick={(e) =>
                                              handleKeyResultAction(
                                                "Child Key Result",
                                                item
                                              )
                                            }
                                          >
                                            Add Key Result
                                          </span>
                                        )}
                                        {OKRFilter == "keyResult" ? (
                                          <span
                                            className="dropdown-item pointer"
                                            onClick={(e) => {
                                              handleKeyResultAction(
                                                "Edit",
                                                item
                                              ),
                                                setchildKeyResult("");
                                            }}
                                          >
                                            Edit
                                          </span>
                                        ) : null}
                                        <span
                                          className="dropdown-item text-danger pointer"
                                          onClick={(e) =>
                                            handleKeyResultAction(
                                              "Delete",
                                              item?._id
                                            )
                                          }
                                        >
                                          Delete
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>  */}
            </div>
          </div>
        </div>
      </div>

      {/* Add Key Result Modal */}
      <div
        className="modal fade create-key-result"
        id="KeyResultChildModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="KeyResultModalChildLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-title">
              <div className="p-3 d-flex justify-content-between align-items-start">
                <div>
                  <h3 className="modal-title" id="KeyResultModalChildLabel">
                    {keyResult?._id || keyResult?.id ? "Edit" : "Create"} Key
                    Result
                  </h3>
                  <p className="mb-1">
                    All required fields are marked with an asterisk (
                    <span className="text-danger">*</span>).
                  </p>
                </div>
                <button
                  type="button"
                  className="close"
                  onClick={(e) => clearKeyResultModal()}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <form onSubmit={handleCreateKeyResult}>
              <div className="modal-body">
                <div className="modal-scroll">
                  <div className="row">
                    <div className="col-md-3 my-2">
                      <label>
                        Title <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-md-9 my-2">
                      <input
                        type="text"
                        value={keyResult?.title}
                        onChange={(e) =>
                          setkeyResult({
                            ...keyResult,
                            title: e.target.value,
                          })
                        }
                        maxLength="70"
                        className="form-control"
                        placeholder="Enter Objective title"
                        required
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Description</label>
                    </div>
                    <div
                      className="w-60"
                      name="description"
                      valuePropName="data"
                      getValueFromEvent={(event, editor) => {
                        const data = editor.getData();
                        return data;
                      }}
                    >
                      <CKEditor
                        data={keyResult?.description}
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          seteditor({ ...editor, keyResult: data });
                        }}
                      />
                    </div>

                    <div className="col-md-3 my-2">
                      <label>Unit</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <select
                        className="form-select"
                        value={keyResult?.unit}
                        onChange={(e) =>
                          setkeyResult({ ...keyResult, unit: e.target.value })
                        }
                      >
                        <option value="">Unit</option>
                        <option value="%">Percentage (%)</option>
                        <option value="$">US Dollar ($)</option>
                        <option value="€">Euro (€)</option>
                      </select>
                    </div>
                    <div className="col-md-3 my-2">
                      <label>
                        Target number <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-md-9 my-2">
                      <input
                        type="number"
                        value={keyResult?.targetNumber}
                        onChange={(e) => {
                          // setsummit(false)
                          setkeyResult({
                            ...keyResult,
                            targetNumber: e.target.value,
                          });
                        }}
                        className="form-control"
                        placeholder="Enter target number"
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      <label>
                        Initial number <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-md-9 my-2">
                      <input
                        type="number"
                        value={keyResult?.initialNumber}
                        onChange={(e) => {
                          // setsummit(false)
                          setkeyResult({
                            ...keyResult,
                            initialNumber: e.target.value,
                          });
                        }}
                        className="form-control"
                        placeholder="Enter initial number"
                      />
                      {summit && (
                        <div style={{ color: "red" }}>
                          Initial value should be smaller than target value
                        </div>
                      )}
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Owners</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <div className="multi-select-box">
                        <Select
                          value={keyResult?.ownerIds}
                          isClearable={true}
                          onChange={(e) =>
                            setkeyResult({ ...keyResult, ownerIds: e })
                          }
                          options={owners}
                          className="basic-multi-select"
                          placeholder="Select Owners"
                          isMulti
                        />
                      </div>
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Deadline</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <select
                        className="form-select"
                        value={keyResult?.deadline}
                        onChange={(e) =>
                          setkeyResult({
                            ...keyResult,
                            deadline: e.target.value,
                          })
                        }
                      >
                        <option value="no custom deadline">
                          No Custom Deadline
                        </option>
                        <option value="hard deadline">Hard Deadline</option>
                        <option value="hard and soft deadline">
                          Hard & Soft Deadline
                        </option>
                      </select>
                    </div>
                    {keyResult?.deadline != "no custom deadline" ? (
                      <>
                        {keyResult?.deadline == "hard and soft deadline" ? (
                          <>
                            <div className="col-md-3 my-2">
                              <label>Soft deadline</label>
                            </div>
                            <div className="col-md-9 my-2">
                              <DatePicker
                                selected={keyResult?.softDeadline}
                                onChange={(date) =>
                                  setkeyResult({
                                    ...keyResult,
                                    softDeadline: date,
                                  })
                                }
                                className="form-control w-100"
                                dateFormat="dd/MMM/yyyy"
                                placeholderText="Soft deadline"
                                minDate={new Date()}
                              />
                            </div>
                          </>
                        ) : null}
                        <div className="col-md-3 my-2">
                          <label>Hard deadline</label>
                        </div>
                        <div className="col-md-9 my-2">
                          <DatePicker
                            selected={keyResult?.hardDeadline}
                            onChange={(date) =>
                              setkeyResult({
                                ...keyResult,
                                hardDeadline: date,
                              })
                            }
                            className="form-control w-100"
                            dateFormat="dd/MMM/yyyy"
                            placeholderText="Hard deadline"
                            minDate={keyResult?.softDeadline || new Date()}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="">
                  <button
                    type="button"
                    id="CloseKeyResultChildModal"
                    onClick={(e) => clearKeyResultModal()}
                    className="btn btn-secondary ml-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={!keyResult?.title || !keyResult?.targetNumber}
                    className="btn btn-primary btn-md mr-2"
                  >
                    {keyResult?._id || keyResult?.id ? "Update" : "Add"} Key
                    Result
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Update Key Result Modal */}
      <div
        className="modal fade"
        id="UpdateKeyResultChildModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="UpdateKeyResultModalChildLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-title">
              <div className="p-3 d-flex justify-content-between align-items-start">
                <div>
                  <h3
                    className="modal-title"
                    id="UpdateKeyResultModalChildLabel"
                  >
                    Update Key Result
                  </h3>
                  <p className="mb-1">
                    All required fields are marked with an asterisk (
                    <span className="text-danger">*</span>).
                  </p>
                </div>
                <button
                  type="button"
                  className="close"
                  onClick={(e) => clearFields()}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>

            <form onSubmit={handleUpdateKeyResult}>
              <div className="modal-body">
                <div className="">
                  <div className="row">
                    <div className="col-md-3 my-2">
                      <label>Current value </label>
                    </div>
                    <div className="col-md-9 my-2">
                      <label>
                        {findUnitSymbol(updateKeyResult)}
                        {valueData}
                        {valueDataUnit == "%" ? "%" : ""}, Should move to{" "}
                        {findUnitSymbol(updateKeyResult)}
                        {updateKeyResult?.goal}
                        {updateKeyResult?.unit == "%" ? "%" : ""}
                      </label>
                    </div>
                    <div className="col-md-3 my-2">
                      <label>New value </label>
                    </div>
                    <div className="col-md-9 my-2">
                      <input
                        type="number"
                        value={updateKeyResult?.newValue}
                        onChange={(e) =>
                          setupdateKeyResult({
                            ...updateKeyResult,
                            newValue: e.target.value,
                          })
                        }
                        className="form-control"
                        placeholder="Enter new value"
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Confidence level</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <ul className="confidence-btns">
                        <li>
                          <button
                            type="button"
                            className={`btn  ${
                              updateKeyResult?.confidenceLevel == "low" ||
                              updateKeyResult?.confidenceLevel == ""
                                ? "btn-primary"
                                : "light-yello-btn"
                            }`}
                            onClick={(e) =>
                              setupdateKeyResult({
                                ...updateKeyResult,
                                confidenceLevel: "low",
                              })
                            }
                          >
                            <GoIssueTracks className="me-2" />
                            OFF TRACK{" "}
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className={`btn ${
                              updateKeyResult?.confidenceLevel == "medium"
                                ? "btn-primary"
                                : "light-yello-btn"
                            }`}
                            onClick={(e) =>
                              setupdateKeyResult({
                                ...updateKeyResult,
                                confidenceLevel: "medium",
                              })
                            }
                          >
                            <CgDanger className="me-2" />
                            AT RISK
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className={`btn mr-2 ${
                              updateKeyResult?.confidenceLevel == "high"
                                ? "btn-primary"
                                : "light-yello-btn"
                            }`}
                            onClick={(e) =>
                              setupdateKeyResult({
                                ...updateKeyResult,
                                confidenceLevel: "high",
                              })
                            }
                          >
                            <GoIssueTrackedBy className="me-2" /> ON TRACK{" "}
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Note</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <CKEditor
                        data={updateKeyResult?.note}
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const newValue = editor.getData();
                          seteditor({ ...editor, noteKeyResult: newValue });
                        }}
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Date of update</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <DatePicker
                        selected={updateKeyResult?.dateOfUpdate}
                        onChange={(date) =>
                          setkeyResult({
                            ...updateKeyResult,
                            dateOfUpdate: date,
                          })
                        }
                        className="form-control w-100"
                        dateFormat="dd/MMM/yyyy"
                        placeholderText="Date Of Update"
                        minDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="">
                  <button
                    type="submit"
                    disabled={!updateKeyResult?.newValue}
                    className="btn btn-primary btn-md mr-2"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    id="CloseUpdateKeyResultChildModal"
                    className="btn btn-secondary ml-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* OKRs Modal */}

      <button
        className="btn btn-primary d-none"
        id="CreateOKRModalChildOpen"
        data-toggle="modal"
        data-target="#OKRsChildModal"
      >
        Create OKRs
      </button>
      <div
        className="modal fade"
        id="OKRsChildModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="OKRsModalChildLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <form onSubmit={handleCreateOKR}>
            <div className="modal-content">
              <div className="modal-title">
                <div className="p-3 d-flex justify-content-between align-items-start">
                  <div>
                    <h3 className="modal-title " id="ManagerModalChildLabel">
                      {detail?.id && !childObjective?._id ? "Update" : "Create"}{" "}
                      Objective
                    </h3>
                    <p className="mb-1">
                      All required fields are marked with an asterisk (
                      <span className="text-danger">*</span>).
                    </p>
                  </div>
                  <button
                    type="button"
                    className="close"
                    onClick={(e) => clearFields()}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <div className="row">
                    <div className="col-md-3 my-2">
                      <label>
                        Session <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-md-9 my-2">
                      <div className="custom-select-btn">
                        <Select
                          className="text-capitalize"
                          value={form?.session}
                          isClearable={true}
                          onChange={(e) => setform({ ...form, session: e })}
                          options={sessions}
                          placeholder="Select Session"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3 my-2">
                      <label>
                        Title <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-md-9 my-2">
                      <input
                        type="text"
                        value={form?.title}
                        onChange={(e) =>
                          setform({ ...form, title: e.target.value })
                        }
                        maxLength="70"
                        className="form-control"
                        placeholder="Enter Objective title"
                        required
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Owner</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <Select
                        value={form?.ownerIds}
                        isClearable={true}
                        onChange={(e) => setform({ ...form, ownerIds: e })}
                        options={owners}
                        className="basic-multi-select text-capitalize"
                        placeholder="Select Owners"
                        isMulti
                      />
                    </div>
                    {childObjective?._id ? (
                      <>
                        <div className="col-md-3">Parent</div>
                        <div className="col-md-9">
                          <Select
                            value={form?.parentId}
                            isClearable={true}
                            onChange={(e) => setform({ ...form, parentId: e })}
                            options={parentKeyResult}
                            className="basic-multi-select text-capitalize"
                            placeholder="Select Parent"
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  disabled={!form?.session || !form?.title || disableed}
                  className="btn btn-primary btn-md mr-2"
                >
                  {detail?.id && !childObjective?._id ? "Update" : "Create"}
                  OKR
                </button>
                <button
                  type="button"
                  onClick={(e) => clearFields()}
                  id="CloseOKRsChildModal"
                  className="btn btn-secondary ml-2"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChildModalOKR;
