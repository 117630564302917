import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

export default function Footer() {
  return (
    <>
      <div className="footer_section py-5 ">
        <div className="container">
          <div className="box_flex">
            <div className="items_footer">
              <div className="mb-3">
                <Link to="/">
                  <img
                    src="assets/img/images/footer_logo.png"
                    className="footer_logo_bx"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className="items_footer">
              <div className="mb-3">
                <h5 className="footer_heading">Company</h5>
                <ul className="list-group footer_list_items">
                  <li className="list-group-item">
                    <Link to="/aboutus">About Us</Link>
                  </li>
                  <li className="list-group-item">
                    <a href="#">Product</a>
                  </li>
                  <li className="list-group-item">
                    <Link to="/plan">Pricing</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="items_footer">
              <div className="mb-3">
                <h5 className="footer_heading">Support</h5>
                <ul className="list-group footer_list_items">
                  <li className="list-group-item">
                    <Link to="/home?section=faqs">FAQ</Link>
                  </li>
                  <li className="list-group-item">
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                  <li className="list-group-item">
                    <Link to="/terms">Terms of Services</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="items_footer">
              <div className="mb-3">
                <h4 className="Subscribe_heading">Subscribe Our Newsletter</h4>
                <p className="footer_signup">
                  Sign up and receive the latest tips <br /> via email.
                </p>

                <div className="position-relative ">
                  <svg
                    className="email_svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1_39)">
                      <path
                        d="M2.33335 3H15.6667C16.5834 3 17.3334 3.75 17.3334 4.66667V14.6667C17.3334 15.5833 16.5834 16.3333 15.6667 16.3333H2.33335C1.41669 16.3333 0.666687 15.5833 0.666687 14.6667V4.66667C0.666687 3.75 1.41669 3 2.33335 3Z"
                        stroke="#39364E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17 5L9 11L1 5"
                        stroke="#39364E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_39">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <input
                    type="email"
                    className="form-control enter_mailbx"
                    id="exampleInputEmail1"
                    placeholder="Enter Your Mail"
                    aria-describedby="emailHelp"
                  />
                  <button className="footer_subscribe_now">
                    Subscribe Now
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="text-center">
              <p className="copy_footer">©2024 Kanam. All right reserved</p>
            </div>
          </div>
        </div>
        <ToastContainer position="top-right" />
      </div>
    </>
  );
}
