import React, { useEffect, useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import Sidebar from "../sidebar";
import Header from "../header";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastsStore } from "react-toasts";
import ReactGA from "react-ga";
import methodModel from "../../../methods/methods";
import { Link } from "react-router-dom";
import { logout } from "../../../actions/user";
import { search_success } from "../../../actions/search";
import { CiAlignRight } from "react-icons/ci";
import { MdDataObject } from "react-icons/md";
import datepipeModel from "../../../models/datepipemodel";

const Layout = ({ children, resources, title = "" }) => {
  const user = useSelector((state) => state.user);
  const searchState = useSelector((state) => state.search);
  const sessions = useSelector((state) => state.session);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const urlPath = window.location.pathname;
  const URLParams = new URLSearchParams(window.location.search);
  const [enableSearch, setenableSearch] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (searchState.data) {
      dispatch(search_success(""));
    }
  }, []);

  useEffect(() => {
    setSearch(searchState.data);
  }, [searchState]);

  const [search, setSearch] = useState("");

  const searchHandle = (e) => {
    e.preventDefault();
    dispatch(search_success(search));
  };

  const searchChange = (e) => {
    setSearch(e);
    if (!e) {
      dispatch(search_success(""));
    }
  };

  const clear = () => {
    setSearch("");
    dispatch(search_success(""));
  };

  const router = () => {
    let route = localStorage.getItem("route");
    history.push(route);
  };

  const componentName = () => {
    let value = urlPath?.replace("/", "").split("/")[0];
    value = value?.replaceAll("-", " ");
    if (value == "okr teams") {
      value = "OKR Teams";
    }
    if (title) value = title;
    return value;
  };

  const showSearch = () => {
    if (
      urlPath == "/teams" ||
      urlPath == "/managers" ||
      urlPath == "/employees"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const trialCheck = () => {
    return methodModel.trialCheck(user);
  };

  return (
    <>
      <div component="layout">
        <div onClick={(e) => router()} id="routerDiv"></div>
        <Header />
        <div
          className={`main-wrapper hegiht100 d-flex overflow-hidden ${
            user?.sidebar ? "active-sidebar" : ""
          }`}
        >
          <ToastContainer position="top-right" />
          <div className="sidebar_new">
            <Sidebar
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              resources={resources}
            />
          </div>

          <main className={`${isOpen ? "half_sides" : "mai_data_full"}`}>
            <div className="header_sidebars">
              <nav className="d-flex justify-content-between align-items-center h-60  width-calc">
                <div className="d-flex gap-4 align-items-center w-100">
                  {isOpen ? (
                    <div
                      className="inner_ful_sides_new pointer"
                      onClick={(e) => setIsOpen(!isOpen)}
                    >
                      <p>
                        <svg
                          viewBox="0 0 16 16"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          data-icon="parent-view"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2 3.25L14 3.25V4.75L2 4.75V3.25ZM2 7.25L14 7.25V8.75L2 8.75V7.25ZM2 11.25L14 11.25V12.75L2 12.75V11.25Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </p>
                    </div>
                  ) : null}
                  <div className="d-flex   align-items-center justify-content-between w-100">
                    <div className="name_s name-margin">
                      <p className="text-capitalize">
                        {urlPath == "/profile?tab=change-pasword"
                          ? "Change Password"
                          : componentName()}
                      </p>
                      <span className="current-page text-capitalize">
                        {sessions.data ? "/" + sessions.data : ""}
                      </span>
                    </div>

                    {/* {sessions.data && urlPath.includes("sessions/okrs") ?<div className="row">
                      <div className="col-md-12">
                        <ul
                          className="nav nav-pills okrs_tabsul"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item w-50" role="presentation">
                            <button
                              className="nav-link active"
                              id="pills-objective-tab"
                              data-toggle="pill"
                              data-target="#pills-objective"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              <span className="mr-2">
                                <MdDataObject />
                              </span>
                              Objectives
                            </button>
                          </li>
                          <li className="nav-item w-50" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-allignment-tab"
                              data-toggle="pill"
                              data-target="#pills-allignment"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                              // onClick={() => getData()}
                            >
                              <span className="mr-2">
                                <CiAlignRight />
                              </span>{" "}
                              Alignment
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>:""} */}
                  </div>
                </div>
                {showSearch() ? (
                  <>
                    {enableSearch ? (
                      <div
                        className="seacrhnar d-flex justify-content-end"
                        onBlur={(e) => {
                          search
                            ? setenableSearch(true)
                            : setenableSearch(false);
                        }}
                      >
                        <form
                          className="headerSearch mr-5"
                          onSubmit={searchHandle}
                        >
                          <input
                            type="text"
                            placeholder="Search..."
                            value={search}
                            onChange={(e) => searchChange(e.target.value)}
                            className="Searchbar"
                          ></input>
                          <i
                            className="fa fa-search"
                            onClick={searchHandle}
                            aria-hidden="true"
                          ></i>
                          {search ? (
                            <i
                              className="fa fa-times"
                              onClick={(e) => {
                                clear(), setenableSearch(false);
                              }}
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <></>
                          )}
                        </form>
                      </div>
                    ) : (
                      <i
                        className="fa fa-search pointer"
                        onClick={(e) => setenableSearch(true)}
                      ></i>
                    )}
                  </>
                ) : null}

                {trialCheck() ? (
                  <>
                    <div className="p-2">
                      {user?.planData?.numberOfDays ? (
                        <>
                          <div className="alert alert-info text-xs p-2 mb-0">
                            Trial Period :{" "}
                            {datepipeModel.getDays(new Date(), user?.trial_end)}{" "}
                            day
                            {datepipeModel.getDays(
                              new Date(),
                              user?.trial_end
                            ) > 1
                              ? "s"
                              : ""}{" "}
                            left{" "}
                            <Link
                              to="/billing-details?plan=true"
                              className="unbderline"
                            >
                              Upgrade Plan
                            </Link>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="alert alert-info text-xs p-2 mb-0">
                            Trial Period : Forever{" "}
                            <Link
                              to="/billing-details?plan=true"
                              className="unbderline"
                            >
                              Upgrade Plan
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {!trialCheck() &&
                !user?.planAmountPaid &&
                user?.planData?.plan_type == "paid" ? (
                  <>
                    <div className="p-2">
                      <div className="alert alert-info text-xs mb-0">
                        Your trial period has been expired{" "}
                        <Link
                          to="/billing-details?plan=true"
                          className="unbderline"
                        >
                          Upgrade Plan
                        </Link>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </nav>
            </div>
            <div className="mainarea">{children}</div>
          </main>
        </div>
      </div>
      {/* <ToastContainer position="top-right" /> */}
    </>
  );
};
export default Layout;
