import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { TreeView } from "@mui/x-tree-view/TreeView";
import {
  TreeItem,
  treeItemClasses,
  useTreeItem,
} from "@mui/x-tree-view/TreeItem";
import { Tooltip } from "antd";
import ApiClient from "../../methods/api/apiClient";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import datepipeModel from "../../models/datepipemodel";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";

const ChildOKR = ({
  data,
  findOKRProgress,
  view = () => {},
  handleUpdateKeyResultModal = () => {},
  ConfidenceLevel = () => {},
  handleAction = () => {},
  handleKeyResultAction = () => {},
  handleKeyModal = () => {},
  delectOkr = () => {},
  valueData,
  setNewValue,
  valueDataUnit,
  setNewValueUnit,
}) => {
  const [allData, setAllData] = useState([...data]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);

  // Hierarchy Tree
  function MinusSquare(props) {
    return (
      <div className="circle-arrow">
        <FaArrowDown />
      </div>
    );
  }
  function PlusSquare(props) {
    if (props?.data?.childExist) {
      return (
        <div className="circle-arrow">
          <FaArrowRightLong />
        </div>
      );
    } else {
      return "";
    }
  }
  function CloseSquare(props) {
    if (!props?.data?.childExist) {
      return (
        <div className="circle-arrow red-colorx">
          <RxCross2 />
        </div>
      );
    } else {
      return "";
    }
  }

  const handleTreeViewChild = (item) => {};

  const findChildExists = (item) => {
    // let value = data && data?.filter(itm => itm?._id == item?._id)
    // if (!value?.[0]?.childExist) return false
    // else return true
    return item?.childExist;
  };

  const setValue = async () => {
    let i = 0;
    let data = allData;
    setLoading(true);
    for await (let item of data) {
      let child = [];
      if (item.child?.length) {
        child = item.child;
      } else {
        if (item.childExist) {
          let prm = {
            parentId: item?.id || item?._id,
            //level: item?.level + 1,
          };
          const res = await ApiClient.get(`okrs`, prm);
          if (res.success) {
            child = res.data;
          }
        }
      }

      data[i].child = child;
      i++;
    }
    setLoading(false);
    setAllData([...data]);
  };

  useEffect(() => {
    if (data.length) {
      setValue();
    }
  }, [data]);

  const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
      classes,
      className,
      label,
      nodeId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
    } = props;

    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      handleSelection,
      preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event) => {
      preventSelection(event);
    };

    const handleExpansionClick = (event) => {
      handleExpansion(event);
    };

    const handleSelectionClick = (event) => {
      handleSelection(event);
    };

    return (
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref}
      >
        <div onClick={handleExpansionClick} className={classes.iconContainer}>
          {icon}
        </div>
        <Typography
          onClick={handleSelectionClick}
          component="div"
          className={classes.label}
        >
          {label}
        </Typography>
      </div>
    );
  });

  const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    return <TreeItem ContentComponent={CustomContent} {...props} ref={ref} />;
  });

  const confidenceLevelClass = (item) => {
    let value = `${
      item?.confidenceLevel == "low" || item?.confidenceLevel == ""
        ? "red-color"
        : item?.confidenceLevel == "medium"
        ? "orange-color"
        : item?.confidenceLevel == "high"
        ? "green-color"
        : ""
    }`;

    if (item?.keyType == "child_objective" || item?.keyType == "okr") {
      value = "";
    }

    return value;
  };

  const keyResultPercent = (item) => {
    return methodModel.keyResultPercent(item);
  };

  return (
    <>
      {allData?.length > 0 ? (
        <>
          {allData &&
            allData?.map((item, index) => {
              return (
                <>
                  {/* {findChildExists(item) ? */}
                  <Box key={item._id}>
                    <TreeView
                      aria-label="customized"
                      // defaultExpanded={allData.length > 0 ? [data[0]?.level + data[0]?.level + 0 + 0] : []}
                      defaultCollapseIcon={<MinusSquare />}
                      defaultExpandIcon={<PlusSquare data={item} />}
                      // defaultEndIcon={<CloseSquare data={item} />}
                      sx={{ overflowX: "hidden" }}
                    >
                      <CustomTreeItem
                        nodeId={item?.level + item?.level + index + index}
                        onClick={(e) => handleTreeViewChild(item)}
                        label={
                          <div className="">
                            <div className=" card_line position-relative align-width">
                              <div className="width-24">
                                <div className="alings_datax d-flex align-items-center align-head">
                                  {item?.keyType == "child_objective" ||
                                  item?.keyType == "okr" ? (
                                    <span className="mr-1 objective-img ">
                                      <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M10.1 2.18a9.93 9.93 0 0 1 3.8 0"></path>
                                        <path d="M17.6 3.71a9.95 9.95 0 0 1 2.69 2.7"></path>
                                        <path d="M21.82 10.1a9.93 9.93 0 0 1 0 3.8"></path>
                                        <path d="M20.29 17.6a9.95 9.95 0 0 1-2.7 2.69"></path>
                                        <path d="M13.9 21.82a9.94 9.94 0 0 1-3.8 0"></path>
                                        <path d="M6.4 20.29a9.95 9.95 0 0 1-2.69-2.7"></path>
                                        <path d="M2.18 13.9a9.93 9.93 0 0 1 0-3.8"></path>
                                        <path d="M3.71 6.4a9.95 9.95 0 0 1 2.7-2.69"></path>
                                        <circle cx="12" cy="12" r="1"></circle>
                                      </svg>
                                    </span>
                                  ) : (
                                    <span className="mr-1 key-img">
                                      <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 24 24"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill="none"
                                          d="M0 0h24v24H0V0z"
                                        ></path>
                                        <path d="M17 4h3v16h-3V4zM5 14h3v6H5v-6zm6-5h3v11h-3V9z"></path>
                                      </svg>
                                    </span>
                                  )}

                                  <span className="fs14 d-flex align-items-center">
                                    {loading ? (
                                      <>
                                        <div>Fetching Your Next Wins...</div>
                                      </>
                                    ) : (
                                      <>
                                        <span
                                          className={`progressDiv mx-2  ${confidenceLevelClass(
                                            item
                                          )}`}
                                        >
                                          {" "}
                                          {item?.keyType == "child_objective" ||
                                          item?.keyType == "okr"
                                            ? findOKRProgress(item?.keyResults)
                                            : keyResultPercent(item) + "%"}
                                        </span>
                                        <span
                                          className={`${
                                            item?.keyType ==
                                              "child_objective" ||
                                            item?.keyType == "okr"
                                              ? "font-weight-bold"
                                              : ""
                                          } fw-600`}
                                          onClick={() => view(item)}
                                        >
                                          {item?.title}
                                        </span>
                                      </>
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="width-74 end-line">
                                <div className="d-flex align-items-center jusendokrs justify-content-between">
                                  <div className="common-w progress-h">
                                    {/* <div className="progress">
                                      <div className="progress-bar progress-bar-color" role="progressbar" style={{
                                                width: (item?.keyType == "child_objective" || item?.keyType == "okr") ? findOKRProgress(item?.keyResults) :
                                                keyResultPercent(item) + "%"
                                            }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div> */}
                                  </div>
                                  <div className="common-w confidence">
                                    {!(
                                      item?.keyType == "child_objective" ||
                                      item?.keyType == "okr"
                                    ) ? (
                                      <>
                                        <ConfidenceLevel data={item} />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="common-w owners">
                                    <div className="owners-number d-flex justify-content-end flex-wrap gap-2">
                                      {item?.ownerDetails?.map((itm, i) => {
                                        if (i < 4)
                                          return (
                                            <>
                                              {itm?.id ? (
                                                // <Tooltip
                                                //   title={itm?.name}
                                                //   placement="top"
                                                // >
                                                //    <NameAvtar name={itm?.name} size="small" />
                                                // </Tooltip>
                                                <span
                                                  style={{
                                                    backgroundColor:
                                                      itm.color || "",
                                                  }}
                                                >
                                                  {itm?.name}
                                                </span>
                                              ) : (
                                                <span className="fs-6">
                                                  <Tooltip
                                                    title="No owner"
                                                    placement="top"
                                                  >
                                                    <svg
                                                      stroke="currentColor"
                                                      fill="currentColor"
                                                      strokeWidth="0"
                                                      viewBox="0 0 640 512"
                                                      height="1em"
                                                      width="1em"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L440.6 320H618.7c11.8 0 21.3-9.6 21.3-21.3C640 239.8 592.2 192 533.3 192H490.7c-15.9 0-31 3.5-44.6 9.7c1.3 7.2 1.9 14.7 1.9 22.3c0 30.2-10.5 58-28 79.9l-25.2-19.7C408.1 267.7 416 246.8 416 224c0-53-43-96-96-96c-31.1 0-58.7 14.8-76.3 37.7l-40.6-31.8c13-14.2 20.9-33.1 20.9-53.9c0-44.2-35.8-80-80-80C116.3 0 91.9 14.1 77.5 35.5L38.8 5.1zM106.7 192C47.8 192 0 239.8 0 298.7C0 310.4 9.6 320 21.3 320H234.7c.2 0 .4 0 .7 0c-20.6-18.2-35.2-42.8-40.8-70.8L121.8 192H106.7zM261.3 352C187.7 352 128 411.7 128 485.3c0 14.7 11.9 26.7 26.7 26.7H485.3c10.5 0 19.5-6 23.9-14.8L324.9 352H261.3zM512 160A80 80 0 1 0 512 0a80 80 0 1 0 0 160z"></path>
                                                    </svg>
                                                  </Tooltip>
                                                </span>
                                              )}
                                            </>
                                          );
                                      })}
                                      {/* {item?.ownerDetails?.length>4&&<small>+more</small>} */}
                                    </div>
                                  </div>
                                  <div className="common-w contributors">
                                    <div className="owners-number d-flex justify-content-end flex-wrap gap-2">
                                      {item?.contributorDetails?.map(
                                        (itm, i) => {
                                          if (i < 4)
                                            return (
                                              <>
                                                {itm?.id ? (
                                                  <span
                                                    style={{
                                                      backgroundColor:
                                                        itm.color || "",
                                                    }}
                                                  >
                                                    {itm?.name}
                                                  </span>
                                                ) : (
                                                  <span className="fs-6">
                                                    <Tooltip
                                                      title="No owner"
                                                      placement="top"
                                                    >
                                                      <svg
                                                        stroke="currentColor"
                                                        fill="currentColor"
                                                        strokeWidth="0"
                                                        viewBox="0 0 640 512"
                                                        height="1em"
                                                        width="1em"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L440.6 320H618.7c11.8 0 21.3-9.6 21.3-21.3C640 239.8 592.2 192 533.3 192H490.7c-15.9 0-31 3.5-44.6 9.7c1.3 7.2 1.9 14.7 1.9 22.3c0 30.2-10.5 58-28 79.9l-25.2-19.7C408.1 267.7 416 246.8 416 224c0-53-43-96-96-96c-31.1 0-58.7 14.8-76.3 37.7l-40.6-31.8c13-14.2 20.9-33.1 20.9-53.9c0-44.2-35.8-80-80-80C116.3 0 91.9 14.1 77.5 35.5L38.8 5.1zM106.7 192C47.8 192 0 239.8 0 298.7C0 310.4 9.6 320 21.3 320H234.7c.2 0 .4 0 .7 0c-20.6-18.2-35.2-42.8-40.8-70.8L121.8 192H106.7zM261.3 352C187.7 352 128 411.7 128 485.3c0 14.7 11.9 26.7 26.7 26.7H485.3c10.5 0 19.5-6 23.9-14.8L324.9 352H261.3zM512 160A80 80 0 1 0 512 0a80 80 0 1 0 0 160z"></path>
                                                      </svg>
                                                    </Tooltip>
                                                  </span>
                                                )}
                                              </>
                                            );
                                        }
                                      )}
                                    </div>
                                  </div>
                                  <div className="common-w last-updates">
                                    <div className="text-end ">
                                      {item?.keyType == "key_result" ? (
                                        <>
                                          <span className="text-xs text-end">
                                            {datepipeModel.getDays(
                                              item.updatedAt,
                                              new Date()
                                            ) == 0 ? (
                                              "Today"
                                            ) : (
                                              <>
                                                {datepipeModel.getDays(
                                                  item.updatedAt,
                                                  new Date()
                                                )}{" "}
                                                Day
                                                {datepipeModel.getDays(
                                                  item.updatedAt,
                                                  new Date()
                                                ) > 1
                                                  ? "s"
                                                  : ""}
                                              </>
                                            )}
                                          </span>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                  <div className="common-w actions">
                                    {user?.role != "employee" && (
                                      <div className="dropsdowns_align spacing_equal text-end">
                                        <div className="dropdown d-flex justify-content-end">
                                          <p
                                            className="text-black pointer"
                                            data-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="fa fa-ellipsis-v text-dark new-ellipses"></i>
                                          </p>
                                          <div className="dropdown-menu custom-dropdown menu-design">
                                            {item?.keyType ==
                                              "child_objective" ||
                                            item?.keyType == "okr" ? (
                                              <>
                                                <span
                                                  className="dropdown-item"
                                                  onClick={(e) => {
                                                    if (
                                                      item.keyType ==
                                                      "child_objective"
                                                    )
                                                      handleAction(
                                                        "Child Objective",
                                                        item
                                                      );
                                                    else
                                                      handleAction(
                                                        "Edit",
                                                        item?._id || item?.id
                                                      );
                                                  }}
                                                >
                                                  <i className="fa fa-pen me-2"></i>{" "}
                                                  Edit
                                                </span>
                                                <span
                                                  className="dropdown-item"
                                                  onClick={(e) => {
                                                    handleKeyModal(item);
                                                  }}
                                                >
                                                  <i className="fa fa-plus me-2"></i>{" "}
                                                  Add Key Result
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                <span
                                                  className="dropdown-item"
                                                  onClick={(e) => {
                                                    console.log(
                                                      "edit in childOKR file"
                                                    );
                                                    console.log(
                                                      "Item clicked:",
                                                      JSON.stringify(
                                                        item,
                                                        null,
                                                        2
                                                      )
                                                    ); // Log the item in JSON format
                                                    setNewValue(
                                                      item?.newValue ||
                                                        item?.initialNumber
                                                    );
                                                    setNewValueUnit(item?.unit);
                                                    handleUpdateKeyResultModal(
                                                      item
                                                    );
                                                  }}
                                                  data-toggle="modal"
                                                  data-target="#UpdateKeyResultModal"
                                                >
                                                  <i className="fa fa-pen me-2"></i>
                                                  Edit
                                                </span>
                                              </>
                                            )}
                                            <span
                                              className="dropdown-item"
                                              onClick={(e) => {
                                                handleKeyResultAction(
                                                  "Child Objective",
                                                  item
                                                );
                                              }}
                                            >
                                              <i className="fa fa-plus me-2"></i>
                                              Add a Child Objective
                                            </span>
                                            <span
                                              className="dropdown-item"
                                              onClick={(e) => {
                                                delectOkr(
                                                  item?.id || item?._id
                                                );
                                              }}
                                            >
                                              <i className="fa fa-trash me-2"></i>
                                              Delete
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      >
                        {item.child?.length > 0 ? (
                          <ChildOKR
                            data={item.child}
                            ConfidenceLevel={ConfidenceLevel}
                            view={view}
                            findOKRProgress={findOKRProgress}
                            handleAction={handleAction}
                            handleKeyResultAction={handleKeyResultAction}
                            handleKeyModal={handleKeyModal}
                            delectOkr={delectOkr}
                            valueData={valueData}
                            setNewValue={setNewValue}
                            valueDataUnit={valueDataUnit}
                            setNewValueUnit={setNewValueUnit}
                            handleUpdateKeyResultModal={
                              handleUpdateKeyResultModal
                            }
                          />
                        ) : null}
                      </CustomTreeItem>
                    </TreeView>
                  </Box>
                  {/* : null} */}
                </>
              );
            })}
        </>
      ) : null}
    </>
  );
};

export default ChildOKR;
