import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import PaymentModal from "../PaymentModal/PaymentModal";
import Layout from "../../components/global/layout";
import OuterHeader from "../../components/OuterHeader";
import OuterFooter from "../../components/OuterFooter";
import methodModel from "../../methods/methods";
import { toast } from "react-toastify";
import Footer from "../../components/global/Footer";

const Plan = () => {
  const [data, setData] = useState([]);
  const [selectEdPlan, setSelectEdPlan] = useState({});
  const [pricing, setPricing] = useState({});
  const [isPlanSelect, setIsPlanSelect] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Profiledata, setProfiledata] = useState();
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    let prm = {
      id: methodModel.getPrams("modal"),
    };
    if (prm?.id) {
      setShow(true);
    }
    if (user?.planId) {
      getData1();
    }
    if (user?.loggedIn) {
      getTotalUser();
    }
  }, []);

  function getPaidPlanFeatures(plans) {
    const paidPlanFeatures = [];
    plans.forEach((plan) => {
      if (plan.plan_type === "paid") {
        let features = plan.features.map((feature) => feature.feature_name);
        features = features.filter(
          (itm) => itm?.status == "active" || !itm?.status
        );
        paidPlanFeatures.push(...features);
      }
    });

    return paidPlanFeatures;
  }

  const paidPlanFeatures = getPaidPlanFeatures(data);

  const getTotalUser = (p = {}) => {
    ApiClient.get(`profile?id=${user?.id}`).then((res) => {
      if (res.success) {
        setProfiledata(res?.data);
      }
    });
  };

  const DeleteHandler = () => {
    ApiClient.delete(`cancel/subscription`).then((res) => {
      if (res?.success) {
        toast.success(res?.success);
        document.getElementById("cancelclose").click();
        getData1();
      }
    });
  };

  const getData1 = (p = {}) => {
    let planType = user?.planData?.plan_type;
    loader(true);
    let url = "plan";

    ApiClient.get(url, { id: user?.planId }).then((res) => {
      if (res.success) {
        setSelectEdPlan(res.data);
        setPricing(
          res.data[0]?.pricing?.length > 0
            ? res.data?.[0].pricing?.[0]?.unit_amount
            : []
        );
      }
      loader(false);
    });
  };

  const sortFeature = (data) => {
    let arry = [];
    data.forEach((element) => {
      element.isChecked ? arry.unshift(element) : arry.push(element);
    });

    return arry;
  };

  const sortPrice = (amt, isFree) => {
    let arry = "";
    data.forEach((element) => {
      element?.pricing?.length > 0 &&
        element?.pricing.forEach((itm) => {
          if (itm.unit_amount > amt) {
            arry = "Upgrade plan";
          } else if (itm.unit_amount < amt) {
            arry = "Current Plan";
          }
        });
    });
    return arry;
  };

  const getData = (p = {}) => {
    let planType = user?.planData?.plan_type;

    loader(true);
    let url = "plans";

    let queryParams = {
      user_id: user?.id,
      status: "active", // Filter for active status
    };

    if (planType === "free" || user?.planAmountPaid) {
      queryParams = {
        ...queryParams,
        user_id: user?.id,
      };
    }

    ApiClient.get(url, queryParams).then((res) => {
      if (res.success) {
        setData(res?.data);
      }
      loader(false);
    });
  };

  useEffect(() => {
    getData({ page: 1 });
  }, []);

  const trialPlan = (selectEdPlan) => {
    let payload = {
      planId: selectEdPlan?._id || selectEdPlan?.id,
      seats: selectEdPlan?.numberOfPeople || 0,
    };
    loader(true);
    ApiClient.post("trial/plan", payload).then((res) => {
      loader(false);
      if (res.success) {
        history.push("/dashboard");
      }
    });
  };

  return (
    <>
      {user?.id ? (
        <Layout>
          <section className="secfifth  ">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="titled_text_fifth ">
                    <header className="text-center mb-5 ">
                      <div className="row">
                        <div className="col-12 col-lg-7 mx-auto">
                          <h2 className="mb-2">Our Pricing</h2>
                          {/* <p>
                            Pay securely online and manage the booking via
                            desktop or via the mobile app.
                          </p> */}
                        </div>
                      </div>
                    </header>

                    <hr />

                    <div className="margin20 plan-m">
                      <div className="row  justify-content-center">
                        {data?.length > 0 &&
                          data.map((itm, index) => {
                            const isCustom =
                              itm?.assigned_member?.length > 0 &&
                              itm.assigned_member.includes(user?.id);
                            if (itm?.plan_type == "custom" && !isCustom) {
                              return;
                            }
                            return (
                              <div
                                className="col-12 col-lg-4 col-xl-4 mb-3 mb-lg-0"
                                key={index}
                              >
                                <div
                                  className={`p-5 ${
                                    itm?.isActive
                                      ? "bg-whites active_card"
                                      : "bg-whites"
                                  }`}
                                >
                                  <div className="text-left">
                                    <h3 className="h6 text-uppercase font-weight-bold mb-4 height-set-head">
                                      {itm?.name}
                                    </h3>

                                    {/* <p className="text-left">
                                      The national average cost of buying coin
                                      easy.
                                    </p> */}
                                  </div>

                                  <div className="custom-separator my-4 mx-auto bg-primary"></div>

                                  {/* <ul className="list-unstyled my-5 text-small text-left features-tick">
                                  {itm?.features?.length > 0 &&
                                    sortFeature(itm?.features).map(
                                      (features, idx) => {
                                        return (
                                          <li
                                            className={`mb-3 ${
                                              features.isChecked
                                                ? ""
                                                : "text-muted"
                                            } `}
                                            key={idx}
                                          >
                                            <i
                                              className={`mr-2 fa ${
                                                features.isChecked
                                                  ? "fa-check  text_primary"
                                                  : "fa-times"
                                              } `}
                                            ></i>{" "}
                                            {features.feature_name}
                                          </li>
                                        );
                                      }
                                    )}
                                </ul> */}

                                  <h2 className="py-3 font-weight-bold">
                                    ${itm?.pricing?.[0]?.unit_amount || 0}
                                    {itm.plan_type !== "free" && (
                                      <span className="text-small font-weight-normal ml-2">
                                        / per month
                                      </span>
                                    )}
                                  </h2>
                                  <div
                                    className={`btn btn-primary btn-block p-2 shadow  mt-3 ${
                                      user?.freePlanBuy &&
                                      itm?.plan_type == "free"
                                        ? "disabled"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      setSelectEdPlan(itm);
                                      user?.id
                                        ? itm.plan_type == "free"
                                          ? trialPlan({ ...itm, trial: true })
                                          : setShow(true)
                                        : history.push("/signup");
                                    }}
                                  >
                                    {itm?.isActive
                                      ? "Current plan"
                                      : user?.planData?.pricing?.[0]
                                          ?.unit_amount <
                                        itm?.pricing?.[0]?.unit_amount
                                      ? "Upgrade Plan"
                                      : "Buy a plan"}
                                  </div>

                                  {itm.plan_type != "free" &&
                                  !itm.isActive &&
                                  !user?.trialUsed ? (
                                    <>
                                      <div
                                        className={`btn btn-primary btn-block p-2 shadow  mt-3`}
                                        onClick={() => {
                                          setSelectEdPlan({
                                            ...itm,
                                            trial: true,
                                          });
                                          user?.id
                                            ? trialPlan({ ...itm, trial: true })
                                            : history.push("/signup");
                                        }}
                                      >
                                        Trial
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <p className="mt-2 free-t-text">
                                    {itm.numberOfDays} day
                                    {itm.numberOfDays > 1 ? "s" : ""} free trial{" "}
                                    <span>(No Credit Card required)</span>
                                  </p>

                                  {/* karm code */}

                                  {itm.isActive === true ? (
                                    <div>
                                      <button
                                        type="button"
                                        className="btn cancel-plan text-center w-100"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                      >
                                        Cancel plan
                                      </button>

                                      <div
                                        className="modal fade"
                                        id="exampleModal"
                                        tabIndex="-1"
                                        aria-labelledby="exampleModalLabel"
                                        aria-hidden="true"
                                      >
                                        <div className="modal-dialog">
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h5
                                                className="modal-title"
                                                id="exampleModalLabel"
                                              >
                                                Cancel Plan
                                              </h5>
                                              <button
                                                type="button"
                                                id={"cancelclose"}
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                              ></button>
                                            </div>
                                            <div className="modal-body">
                                              <div className="cancel-plan-modal">
                                                <img src="assets/img/delete.svg" />
                                                <h4>
                                                  Are you sure you want to
                                                  cancel your plan ?
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="btn btn-primary"
                                                  onClick={(e) =>
                                                    DeleteHandler()
                                                  }
                                                >
                                                  Yes
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            );

                            // ) : (
                            // itm?.pricing?.length > 0 &&
                            // itm.pricing.map((data, indx) => {
                            //   const isTrue =
                            //     selectEdPlan?.pricing?.length > 0 &&
                            //     selectEdPlan.pricing.some((obj) => {
                            //       return (
                            //         obj.interval_count == data.interval_count
                            //       );
                            //     });

                            //   return (
                            //     <div
                            //       className="col-12 col-lg-4 col-xl-4 mb-5 mb-lg-0"
                            //       key={indx}
                            //     >
                            //       <div
                            //         className={`p-5 ${itm?.isActive
                            //           ? "bg-whites active_card"
                            //           : "bg-whites"
                            //           }`}
                            //       >
                            //         <div className="text-left">
                            //           <h3 className="h6 text-uppercase font-weight-bold mb-4">
                            //             {itm?.name}
                            //           </h3>
                            //           <p className="text-left">
                            //             The national average cost of buying
                            //             coin easy.
                            //           </p>

                            //         </div>

                            //         <div className="custom-separator my-4 mx-auto bg-primary"></div>

                            //         <ul className="list-unstyled my-5 text-small text-left features-tick">
                            //           {itm?.features?.length > 0 &&
                            //             sortFeature(itm?.features).map((features, idx) => {
                            //               return (
                            //                 <li
                            //                   className={`mb-3 ${features.isChecked
                            //                     ? ""
                            //                     : "text-muted"
                            //                     } `}
                            //                   key={idx}
                            //                 >
                            //                   <i
                            //                     className={`mr-2 fa ${features.isChecked
                            //                       ? "fa-check  text_primary"
                            //                       : "fa-times"
                            //                       } `}
                            //                   ></i>{" "}
                            //                   {features.feature_name}
                            //                 </li>
                            //               );
                            //             })
                            //           }
                            //         </ul>

                            //         <h2 className="py-3 font-weight-bold">
                            //           ${data.unit_amount}
                            //           <span className="text_small font-weight-normal ml-2">
                            //             /{" "}
                            //             {`${data.interval_count == 12
                            //               ? "year"
                            //               : data.interval_count == 1
                            //                 ? "month"
                            //                 : data.interval_count +
                            //                 " " +
                            //                 "month"
                            //               }`}
                            //           </span>
                            //         </h2>
                            //         <div
                            //           className={`btn btn-primary btn-block p-2 shadow rounded-pill mt-3`}

                            //           onClick={(e) => {
                            //             setSelectEdPlan(itm);
                            //             setPricing(data);
                            //             setIsPlanSelect(
                            //               isPlanSelect &&
                            //                 selectEdPlan?._id == itm?._id &&
                            //                 isTrue
                            //                 ? false
                            //                 : true
                            //             );
                            //             user?.id
                            //               ? setShow(true)
                            //               : history.push("/signup");
                            //           }}
                            //         >
                            //           {
                            //
                            // }
                            //           {itm?.isActive &&
                            //             itm?.plan_type == "free"
                            //             ? "Upgrade plan"
                            //             :

                            //             itm?.isActive &&
                            //               itm?.plan_type == "paid"
                            //               ? "Upgrade"
                            //               : sortPrice(data.unit_amount)

                            //           }
                            //         </div>
                            //       </div>
                            //     </div>
                            //   );
                            // })
                            // );
                          })}
                      </div>
                      <div className="">
                        <div className="d-flex feature-main">
                          <div className="table-feature-head-left">
                            Features
                          </div>
                          <div className="table-feature-head-right row">
                            {data?.length > 0 &&
                              data.map((itm) => {
                                return (
                                  <>
                                    <div className="col">
                                      <div className="">{itm?.name}</div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>
                        <div className="features-display mb-5">
                          <div className="feature-display-left">
                            {
                              <>
                                <>
                                  <div className="p-3">
                                    {paidPlanFeatures.map((features, idx) => {
                                      return (
                                        <>
                                          <div className={`mb-4  `} key={idx}>
                                            {features}
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                </>
                              </>
                            }
                          </div>

                          <div className="feature-display-right">
                            <div className="features-inside row">
                              {data.map((item, index) => (
                                <>
                                  <div className="feature-inside-one col">
                                    <div className="feature-pointers">
                                      <div className="p-3" key={index}>
                                        {item?.features?.length > 0 &&
                                          sortFeature(item?.features).map(
                                            (features, idx) => {
                                              return (
                                                <div
                                                  className={`mb-4 ${
                                                    features.isChecked ? "" : ""
                                                  } `}
                                                  key={idx}
                                                >
                                                  <div className="circle-tick">
                                                    <i
                                                      className={` fa ${
                                                        features.isChecked
                                                          ? "fa-check  text_primary"
                                                          : "fa-times"
                                                      } `}
                                                    ></i>
                                                  </div>{" "}
                                                  {/* {features.feature_name} */}
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {show && (
              <PaymentModal
                show={show}
                handleClose={handleClose}
                handleShow={handleShow}
                paymentData={selectEdPlan}
                pricing={pricing}
              />
            )}
          </section>
          {/* <ToastContainer position="top-right" / >  */}
        </Layout>
      ) : (
        <>
          <OuterHeader />
          <section className="secfifth  common-padding">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="titled_text_fifth">
                    <header className="text-center mb-5 ">
                      <div className="row">
                        <div className="col-12 col-lg-7 mx-auto">
                          <h2>Our Pricing</h2>
                          {/* <p>
                            Pay securely online and manage the booking via
                            desktop or via the mobile app.{" "}
                          </p> */}
                        </div>
                      </div>
                    </header>

                    <div className="row  ">
                      {data?.length > 0 &&
                        data.map((itm, index) => {
                          const isCustom =
                            itm?.assigned_member?.length > 0 &&
                            itm.assigned_member.includes(user?.id);

                          if (itm?.plan_type == "custom" && !isCustom) {
                            return;
                          }

                          // return itm.plan_type == "free" ? (
                          return (
                            <div
                              className="col-12 col-lg-4 col-xl-4 mb-3 mb-lg-0"
                              key={index}
                            >
                              <div className="recommended special-text">
                                {itm?.recommended == "yes" ? (
                                  <p className="special-text__tooltip">
                                    Recommended
                                  </p>
                                ) : null}
                              </div>

                              <div
                                className={`p-5 ${
                                  isPlanSelect && selectEdPlan?._id == itm?._id
                                    ? "active_card"
                                    : "bg-whites"
                                }`}
                              >
                                <div className="text-center">
                                  <h3 className="h6 text-uppercase font-weight-bold mb-4 text-left height-manage">
                                    {itm?.name}
                                  </h3>
                                  {/* <p className="text-left">
                                    The national average cost of buying coin
                                    easy.
                                  </p> */}
                                </div>

                                <div className="custom-separator my-4 mx-auto bg-primary"></div>

                                {/* <ul className="list-unstyled my-5 text-small text-left features-tick">
                                  {itm?.features?.length > 0 &&
                                    sortFeature(itm?.features).map(
                                      (features, idx) => {
                                        return (
                                          <li
                                            className={`mb-3 ${
                                              features.isChecked
                                                ? ""
                                                : "text-muted"
                                            } `}
                                            key={idx}
                                          >
                                            <i
                                              className={`mr-2 fa ${
                                                features.isChecked
                                                  ? "fa-check  text_primary"
                                                  : "fa-times"
                                              } `}
                                            ></i>{" "}
                                            {features.feature_name}
                                          </li>
                                        );
                                      }
                                    )}
                                </ul> */}

                                <h2 className="h1 font-weight-bold price-text">
                                  ${itm?.pricing?.[0]?.unit_amount | 0}
                                  {itm.plan_type !== "free" && (
                                    <span className="text-small font-weight-normal ml-2">
                                      / per month
                                    </span>
                                  )}
                                </h2>
                                <div
                                  className="btn btn-primary btn-block p-2 shadow  mt-3"
                                  onClick={() => {
                                    setSelectEdPlan(itm);
                                    setIsPlanSelect(
                                      isPlanSelect &&
                                        selectEdPlan?._id == itm?._id
                                        ? false
                                        : true
                                    );
                                    user?.id
                                      ? setShow(true)
                                      : history.push(
                                          "/signup?plan_id=" + itm?._id
                                        );
                                  }}
                                >
                                  Select Plan
                                </div>

                                {itm.numberOfDays ? (
                                  <>
                                    <p className="mt-2 free-t-text">
                                      {itm.numberOfDays} day
                                      {itm.numberOfDays > 1 ? "s" : ""} free
                                      trial{" "}
                                      <span>(No Credit Card required)</span>
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <p className="mt-2 free-t-text">
                                      Forever{" "}
                                      <span>(No Credit Card required)</span>
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                          );
                          // ) : (<></>
                          // itm?.pricing?.length > 0 &&
                          // itm.pricing.map((data, indx) => {
                          //   const isTrue =
                          //     selectEdPlan?.pricing?.length > 0 &&
                          //     selectEdPlan.pricing.some((obj) => {
                          //       return (
                          //         obj.interval_count == data.interval_count
                          //       );
                          //     });

                          //   return (
                          //     <div
                          //       className="col-12 col-lg-4 col-xl-4 mb-5 mb-lg-0"
                          //       key={indx}
                          //     >

                          //       <div
                          //         className={`p-5 ${isPlanSelect &&
                          //           selectEdPlan?._id == itm?._id &&
                          //           isTrue
                          //           ? "bg-whites active_card"
                          //           : "bg-whites"
                          //           }`}
                          //       >
                          //         <div className="text-center">
                          //           <h3 className="h6 text-uppercase font-weight-bold mb-4 text-left">
                          //             {itm?.name}
                          //           </h3>
                          //           <p className="text-left">
                          //             The national average cost of buying
                          //             coin easy.
                          //           </p>

                          //         </div>

                          //         <div className="custom-separator my-4 mx-auto bg-primary"></div>

                          //         <ul className="list-unstyled my-5 text-small text-left features-tick">
                          //           {itm?.features?.length > 0 &&
                          //             sortFeature(itm?.features).map((features, idx) => {
                          //               return (
                          //                 <li
                          //                   className={`mb-3 ${features.isChecked
                          //                     ? ""
                          //                     : "text-muted"
                          //                     } `}
                          //                   key={idx}
                          //                 >
                          //                   <i
                          //                     className={`mr-2 fa ${features.isChecked
                          //                       ? "fa-check  text_primary"
                          //                       : "fa-times"
                          //                       } `}
                          //                   ></i>{" "}
                          //                   {features.feature_name}
                          //                 </li>
                          //               );
                          //             })}
                          //         </ul>
                          //         <h2 className="h1 font-weight-bold price-text">
                          //           ${data.unit_amount}
                          //           <span className="text-small font-weight-normal ml-2">
                          //             /{" "}
                          //             {`${data.interval_count == 12
                          //               ? "year"
                          //               : data.interval_count == 1
                          //                 ? "month"
                          //                 : data.interval_count +
                          //                 " " +
                          //                 "month"
                          //               }`}
                          //           </span>
                          //         </h2>
                          //         <div
                          //           className="btn btn-primary btn-block p-2 shadow rounded-pill mt-3"
                          //           onClick={() => {
                          //             setSelectEdPlan(itm);
                          //             setPricing(data);
                          //             setIsPlanSelect(
                          //               isPlanSelect &&
                          //                 selectEdPlan?._id == itm?._id &&
                          //                 isTrue
                          //                 ? false
                          //                 : true
                          //             );
                          //             user?.id
                          //               ? setShow(true)
                          //               : history.push("/signup?plan_id=" + itm?._id);
                          //           }}
                          //         >
                          //           Select Plan
                          //         </div>
                          //       </div>
                          //     </div>
                          //   );
                          // })
                          // );
                        })}

                      {/* <div className="col-12 col-lg-4 col-xl-4 mb-5 mb-lg-0">
                        <div className="bg-whites p-5 h-100">
                          <div className="text-left d-flex justify-content-between flex-column h-100">
                          <div className="h-50">
                          <h3 className="h6 text-uppercase font-weight-bold mb-4">
                              Enterprise
                            </h3>
                            <p className="text-left ">
                              For larger teams and organizations that need more
                              advanced and custom capabilities, including
                              security, control, and support.
                            </p>
                          </div>
                           <div className="h-50 d-flex justify-content-end flex-column">
                           <h6 className="h1  price-text">
                              Custom quote
                            </h6>
                            <a
                              href="#"
                              className="btn btn-primary btn-block p-2 shadow rounded-pill mt-3"
                            >
                              Contact Sales
                            </a>
                           </div>
                          </div>

                          <div className="custom-separator my-4 mx-auto bg-primary"></div>
                        </div>
                      </div> */}
                    </div>
                    <div className="">
                      <div className="d-flex feature-main">
                        <div className="table-feature-head-left">Features</div>
                        <div className="table-feature-head-right row">
                          {data?.length > 0 &&
                            data.map((itm) => {
                              return (
                                <>
                                  <div className="col">
                                    <div className="">{itm?.name}</div>
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>
                      <div className="features-display mb-5">
                        <div className="feature-display-left">
                          {
                            <>
                              <>
                                <div className="p-3">
                                  {paidPlanFeatures.map((features, idx) => {
                                    return (
                                      <>
                                        <div className={`mb-4  `} key={idx}>
                                          {features}
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </>
                            </>
                          }
                        </div>

                        <div className="feature-display-right">
                          <div className="features-inside row">
                            {data.map((item, index) => (
                              <>
                                <div className="feature-inside-one col">
                                  <div className="feature-pointers">
                                    <div className="p-3" key={index}>
                                      {item?.features?.length > 0 &&
                                        sortFeature(item?.features).map(
                                          (features, idx) => {
                                            return (
                                              <div
                                                className={`mb-4 ${
                                                  features.isChecked ? "" : ""
                                                } `}
                                                key={idx}
                                              >
                                                <div className="circle-tick">
                                                  <i
                                                    className={` fa ${
                                                      features.isChecked
                                                        ? "fa-check  text_primary"
                                                        : "fa-times"
                                                    } `}
                                                  ></i>
                                                </div>{" "}
                                                {/* {features.feature_name} */}
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {show && (
              <PaymentModal
                show={show}
                handleClose={handleClose}
                handleShow={handleShow}
                paymentData={selectEdPlan}
                pricing={pricing}
              />
            )}
          </section>
          <Footer />
        </>
      )}
      {/* <ToastContainer position="top-right" / >  */}
    </>
  );
};

export default Plan;
