import { Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";
import SelectDropdown from "../../components/common/SelectDropdown";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { PiIdentificationBadge } from "react-icons/pi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const TeamDetail = () => {
  const { id } = useParams();
  const user = useSelector((state) => state?.user);
  const [detail, setdetail] = useState();
  const [text, setText] = useState("");
  const [teams, setTeams] = useState([]);
  const [tab, setTab] = useState("overview");
  const [form, setform] = useState({
    edit: false,
    fullName: "",
    image: "",
    color: "",
    description: "",
    vision: "",
    depend_upon: [],
    depend_uponus: [],
    parentTeam: "",
    managerId: [],
    employees: [],
  });
  const [values, setValues] = useState([]);
  const [valueForm, setValueForm] = useState({ title: "", description: "" });
  const [valueLoader, setValueLoader] = useState(false);
  const [Invites, setInvites] = useState();
  const [showOptions, setshowOptions] = useState(true);
  const [managerData, setManagerData] = useState([]);
  const navigate = useHistory();

  useEffect(() => {
    if (id) {
      getDetail();
      getInvitesDetail();
      getManagerList();
    }
    getTeams();
  }, [id]);

  const viewTeam = (id) => {
    navigate.push(`/team/${id}`);
  };

  const getDetail = () => {
    loader(true);
    ApiClient.get(`team?id=${id}`).then((res) => {
      if (res.success) {
        let data = res.data;
        setdetail(res?.data);
        let payload = form;
        Object.keys(payload).map((itm) => {
          payload[itm] = data[itm];
        });
        setform({
          ...payload,
          edit: false,
          managerId: res?.data?.managerId?.id || null,
          parentTeam: res?.data?.parentTeam?.id || null,
        });
        setValues(data.values || []);
      }
      loader(false);
    });
  };

  const getInvitesDetail = () => {
    ApiClient.get(`invites?teamId=${id}`).then((res) => {
      if (res?.success) {
        setInvites(res?.data);
      }
    });
  };

  const getManagerList = (p = {}) => {
    let filt = { ...p, addedBy: user?.id || user?._id };
    ApiClient.get(`users/list`, filt).then((res) => {
      if (res.success) {
        let val = res?.data || [];
        if (val?.length > 0) {
          val = val.map((item) => {
            return {
              id: item?.id || item?._id,
              email: item?.email || item?.employeeEmail,
            };
          });
          setManagerData(val);
        }
      }
    });
  };

  const getTeams = (p = {}) => {
    let filt = { ...p, addedBy: user?.id || user?._id };
    ApiClient.get(`teams`, filt).then((res) => {
      if (res.success) {
        let data = res?.data || [];
        data = data.map((itm) => {
          itm.id = itm._id;
          return itm;
        });
        data = data.filter((itm) => itm.id != id);
        setTeams(data);
      }
    });
  };

  const handleUpdate = () => {
    let payload = {
      id: detail?.id,
      ...form,
    };

    Object.keys(payload).map((itm) => {
      payload[itm] = payload[itm] || null;
    });

    if (!payload.image) payload.image = "";
    if (!payload.vision) payload.vision = "";
    if (!payload.description) payload.description = "";
    if (!payload.color) payload.color = "";

    delete payload.edit;
    ApiClient.put(`team`, payload).then((res) => {
      if (res.success) {
        getDetail();
        toast.success(res?.message);
        setform({ edit: false });
      }
    });
  };

  const updateValues = (values, method = "") => {
    let payload = {
      id: detail?.id,
      values: values,
    };
    setValueLoader(true);
    ApiClient.put(`team`, payload).then((res) => {
      setValueLoader(false);
      if (res.success) {
        getDetail();
        // toast.success(res?.message);
        setValues([...values]);

        if (method != "delete") {
          let el = document.getElementById("valuePopupBtn");
          if (el) el.click();
        }
      }
    });
  };

  const handleCancel = () => {
    setform({ edit: false });
    getDetail();
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Do you really want to delete this team ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true);
        ApiClient.delete(`team?id=${id}`).then((res) => {
          if (res.success) {
            setTimeout(() => {
              toast.success(res.message);
            }, 100);
            navigate.push(`/teams`);
          }
          loader(false);
        });
      }
    });
  };

  const handleStatus = (id) => {
    let status = detail?.status == "active" ? "deactive" : "active";
    if (window.confirm("Do you really want to change status of this team")) {
      ApiClient.put(`change/status?model=team&id=${id}&status=${status}`).then(
        (res) => {
          if (res.success) {
            toast.success(res?.message);
            getDetail();
          }
        }
      );
    }
  };

  const openValue = (p = {}) => {
    if (form.edit) {
      handleUpdate(); // Save the form data if in edit mode
    }
    let el = document.getElementById("valuePopupBtn");
    if (el) el.click();

    setValueForm({
      id: "",
      title: "",
      description: "",
      ...p,
    });
  };

  const valueSubmit = () => {
    let arr = values;
    if (valueForm?.id) {
      let index = arr.map((e) => e.id).indexOf(valueForm.id);
      arr[index] = valueForm;
    } else {
      let id = String(new Date().getTime());
      arr.push({ ...valueForm, id });
    }

    updateValues(arr);
  };

  const deleteValue = (id) => {
    let arr = values.filter((itm) => itm.id != id);
    updateValues(arr, "delete");
  };

  const uploadImage = (e) => {
    let files = e.target.files;
    loader(true);
    ApiClient.multiImageUpload("upload/image", files, {
      modelName: "users",
    }).then((res) => {
      if (res.success) {
        let image = res.data.fullpath;
        ApiClient.put(`team`, { image: image, id: detail?.id }).then((dres) => {
          loader(false);
          if (dres.success) {
            setform({ ...form, image: image });
          }
        });
      } else {
        loader(false);
      }
    });
  };

  console.log(form?.vision, "??????");

  return (
    <>
      <Layout>
        <div className="d-flex  tabs-new  mb-5 headertopteams">
          <button
            type="button"
            className={`btn w-50 ${
              tab == "overview" ? "btn-team" : "btn-secondary"
            }`}
            style={{ borderRadius: "8px 0px 0px 0px" }}
            onClick={() => {
              setTab("overview");
            }}
          >
            <MdOutlineLibraryBooks className="me-2" />
            Overview
          </button>
          <button
            type="button"
            className={`btn  w-50 ${
              tab == "identity" ? "btn-team" : "btn-secondary"
            }`}
            style={{ borderRadius: "0px 8px 0px 0px" }}
            onClick={() => {
              setTab("identity");
            }}
          >
            <PiIdentificationBadge className="me-2" />
            Our Identity
          </button>
        </div>
        <section className="details_okrs_members mt-4  team-full-detail mb-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-7 col-xl-9 col-lg-11">
                <div className="bg-white p-3 white-bg three-corners">
                  <div className="team-profile team-profile-centered sp-pt-10 d-flex justify-content-between">
                    <div className="temasheader">
                      <div className="d-flex align-items-center">
                        <label className="position-relative labeledit bgdata_ofs mb-0 pointer">
                          <img
                            src={methodModel.userImg(form?.image)}
                            width=""
                          />
                          <i className="fa fa-edit position-absolute bottom-0 right-0"></i>
                          <input
                            type="file"
                            className="d-none"
                            accept="image/*"
                            onChange={(e) => {
                              uploadImage(e);
                            }}
                          />
                          {/* <img
                        src="/assets/img/login_Img.png"
                        className="okrs_imgs"
                      /> */}
                        </label>
                        <span className="ml-3">
                          <div className="d-flex  justify-content-between">
                            <h3 className="team-head-new text-capitalize">
                              {detail?.fullName}
                            </h3>
                          </div>
                          <p className="creation-date">
                            Created on{" "}
                            {moment(detail?.createdAt).format("MMMM DD, YYYY")}
                          </p>
                        </span>
                      </div>
                    </div>
                    {showOptions && !user?.isEmployee && (
                      <div className="dropdowns_custom ">
                        <div className="dropdown ms-4 mt-2">
                          <span
                            className=" text-dark p-2 dotted"
                            type="button"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i
                              className="fa fa-ellipsis-h text-dark"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <div className="dropdown-menu">
                            <span
                              className="dropdown-item pointer"
                              onClick={(e) => setform({ ...form, edit: true })}
                            >
                              <i className="fa fa-pen me-2"></i> Edit
                            </span>

                            <span
                              className="dropdown-item pointer"
                              onClick={(e) => handleStatus(detail?.id)}
                            >
                              <i
                                className="fa fa-users me-2"
                                aria-hidden="true"
                              ></i>
                              {detail?.status == "active"
                                ? "Deactivate"
                                : "Activate"}{" "}
                              Team
                            </span>
                            <span
                              className="dropdown-item text-danger pointer"
                              onClick={(e) => handleDelete(detail?.id)}
                            >
                              <i className="fa fa-trash me-2"></i>
                              Delete Team
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {tab == "overview" ? (
                    <>
                      <ul className="detail-view-list">
                        <li className="d-flex align-items-start">
                          <p className="flex-half">
                            <b>Team Name</b>
                            <br />
                            Enter a unique name to identify this team across the
                            organization.
                          </p>

                          <div className="w-60 flex-half">
                            {form.edit ? (
                              <>
                                <input
                                  type="text"
                                  className="form-control move-left"
                                  value={form.fullName}
                                  disabled={!form?.edit}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      fullName: e.target.value,
                                    });
                                  }}
                                  required
                                />
                              </>
                            ) : (
                              <>
                                <p className="p flex-half move-left">
                                  {detail?.fullName.charAt(0).toUpperCase() +
                                    detail?.fullName.slice(1)}
                                </p>
                              </>
                            )}
                          </div>
                        </li>
                        <div className="new-separator-bar"></div>{" "}
                        <li className="d-flex align-items-center">
                          <p className="mb-0 flex-half">
                            <b>Team Manager</b>
                            <br />
                            Specify the primary point of contact responsible for
                            leading this team.
                          </p>
                          {form?.edit ? (
                            <div className="w-60 flex-half move-left">
                              <SelectDropdown
                                intialValue={form?.managerId}
                                options={managerData}
                                displayValue={"email"}
                                placeholder="Select Manager"
                                theme="search"
                                result={(e) => {
                                  setform({ ...form, managerId: e.value });
                                }}
                              />
                            </div>
                          ) : (
                            (console.log(detail?.managerId, "managerid"),
                            (
                              <div className="w-60 flex-half move-left">
                                <div className=" ui-chip">
                                  <span className="text-capitalize ui-chip-icon ui-chip-icon-bg">
                                    <img
                                      src={methodModel.userImg(
                                        detail?.managerId?.image
                                      )}
                                      className="imgperson_op"
                                    />
                                    {detail?.managerId?.fullName || "--"}{" "}
                                  </span>
                                </div>
                              </div>
                            ))
                          )}
                        </li>
                        <div className="new-separator-bar"></div>
                        <li className="d-flex align-items-center">
                          <p className="mb-0 flex-half">
                            <b>Team Members</b>
                            <br />
                            Add all individuals who are part of this team. Their
                            roles and responsibilities will align with the
                            team’s objectives.
                          </p>
                          {form?.edit ? (
                            <div className="multiple-user w-60 move-left">
                              <div className="multiselect-custom">
                                <MultiSelectDropdown
                                  intialValue={form?.employees}
                                  placeholder="Select Members"
                                  options={managerData}
                                  result={(e) => {
                                    setform({ ...form, employees: e.value });
                                  }}
                                  displayValue="email"
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="multiple-user d-flex align-items-center w-60 flex-half move-left">
                              {detail?.employeeDetails?.length > 0 ? (
                                detail?.employeeDetails?.map((item) => {
                                  return item?.fullName ? (
                                    <div className="ui-chip me-1">
                                      <span className="text-capitalize ui-chip-icon ui-chip-icon-bg">
                                        <img
                                          src={methodModel.userImg(item.image)}
                                          className="imgperson_op"
                                        />
                                        {item?.fullName}
                                      </span>
                                    </div>
                                  ) : item?.email ? (
                                    <span className="me-3 text-capitalize ">
                                      <img
                                        src={methodModel.userImg(item.image)}
                                        className="imgperson_op"
                                      />
                                      {item?.email.split("@")[0]}
                                    </span>
                                  ) : null;
                                })
                              ) : (
                                <></>
                              )}

                              {Invites?.length > 0 ? (
                                Invites?.map((item) => {
                                  return (
                                    <span className=" text-capitalize">
                                      {" "}
                                      <img
                                        src="/assets/img/person.jpg"
                                        className="imgperson_op"
                                      />{" "}
                                      {item?.employeeEmail?.split("@")[0]}{" "}
                                    </span>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </div>
                          )}
                        </li>
                        <div className="new-separator-bar"></div>{" "}
                        <li className="d-flex align-items-start">
                          <p className="flex-half">
                            <b>Dependencies on Other Teams</b>
                            <br />
                            List the teams this group depends on to achieve its
                            objectives. Dependencies indicate areas where
                            collaboration or resources are needed.
                          </p>
                          {form.edit ? (
                            <>
                              <div className="w-60 flex-half move-left">
                                <MultiSelectDropdown
                                  intialValue={form?.depend_upon}
                                  placeholder="Select Teams"
                                  options={teams}
                                  result={(e) => {
                                    setform({ ...form, depend_upon: e.value });
                                  }}
                                  displayValue="fullName"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="multiple-user d-flex align-items-center w-60 flex-half move-left">
                                {detail?.depend_team_details?.length > 0 ? (
                                  detail?.depend_team_details?.map((item) => {
                                    return item?.fullName ? (
                                      <div className="ui-chip">
                                        <span
                                          className=" text-capitalize ui-chip-icon ui-chip-icon-bg pointer"
                                          onClick={() => viewTeam(item.id)}
                                        >
                                          <img
                                            src={methodModel.userImg(
                                              item.image
                                            )}
                                            className="imgperson_op"
                                          />
                                          {item?.fullName}
                                        </span>
                                      </div>
                                    ) : item?.email ? (
                                      <span
                                        className="me-3 text-capitalize pointer"
                                        onClick={() => viewTeam(item.id)}
                                      >
                                        <img
                                          src={methodModel.userImg(item.image)}
                                          className="imgperson_op"
                                        />
                                        {item?.email.split("@")[0]}
                                      </span>
                                    ) : null;
                                  })
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          )}
                        </li>
                        <div className="new-separator-bar"></div>{" "}
                        <li className="d-flex align-items-start">
                          <p className="flex-half">
                            <b>Teams Dependent on Us</b>
                            <br />
                            Specify the teams that rely on this team to
                            accomplish their goals. Understanding these
                            dependencies can aid in prioritizing tasks and
                            resources.
                          </p>
                          {form.edit ? (
                            <>
                              <div className="w-60 flex-half move-left">
                                <MultiSelectDropdown
                                  intialValue={form?.depend_uponus}
                                  placeholder="Select Teams"
                                  options={teams}
                                  result={(e) => {
                                    setform({
                                      ...form,
                                      depend_uponus: e.value,
                                    });
                                  }}
                                  displayValue="fullName"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="w-60 multiple-user d-flex align-items-center flex-wrap flex-half move-left">
                                {detail?.depend_uponus_details?.length > 0 ? (
                                  detail?.depend_uponus_details?.map((item) => {
                                    return item?.fullName ? (
                                      <div className="ui-chip mb-1 me-1">
                                        <span
                                          className=" text-capitalize ui-chip-icon ui-chip-icon-bg pointer"
                                          onClick={() => viewTeam(item.id)}
                                        >
                                          <img
                                            src={methodModel.userImg(
                                              item.image
                                            )}
                                            className="imgperson_op"
                                          />
                                          {item?.fullName}
                                        </span>
                                      </div>
                                    ) : item?.email ? (
                                      <span
                                        className=" text-capitalize pointer"
                                        onClick={() => viewTeam(item.id)}
                                      >
                                        <img
                                          src={methodModel.userImg(item.image)}
                                          className="imgperson_op"
                                        />
                                        {item?.email.split("@")[0]}
                                      </span>
                                    ) : null;
                                  })
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          )}
                        </li>
                        <div className="new-separator-bar"></div>{" "}
                        <li className="d-flex align-items-start">
                          <p className="flex-half">
                            <b>Identified as a Dependency by Other Teams</b>
                            <br />
                            See which teams have independently marked this team
                            as a dependency. Use this for cross-functional
                            alignment and clarity on support needs.
                          </p>
                          <div className=" w-60 multiple-user d-flex align-items-center flex-half move-left">
                            {detail?.identified_as_a_dependency_details
                              ?.length > 0 ? (
                              detail?.identified_as_a_dependency_details?.map(
                                (item) => {
                                  return item?.fullName ? (
                                    <div className="ui-chip">
                                      <span
                                        className=" text-capitalize ui-chip-icon ui-chip-icon-bg cur pointer"
                                        onClick={() => viewTeam(item.id)}
                                      >
                                        <img
                                          src={methodModel.userImg(item.image)}
                                          className="imgperson_op"
                                        />
                                        {item?.fullName}
                                      </span>
                                    </div>
                                  ) : item?.email ? (
                                    <span
                                      className="me-3 text-capitalize pointer"
                                      onClick={() => viewTeam(item.id)}
                                    >
                                      <img
                                        src={methodModel.userImg(item.image)}
                                        className="imgperson_op"
                                      />
                                      {item?.email.split("@")[0]}
                                    </span>
                                  ) : null;
                                }
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </li>
                        <div className="new-separator-bar"></div>{" "}
                        <li className="d-flex align-items-start">
                          <p className="flex-half">
                            <b>Parent Team</b>
                            <br />
                            Select the parent team if this team is part of a
                            larger organizational unit.
                          </p>
                          {form.edit ? (
                            <>
                              <div className="w-60 flex-half move-left">
                                <SelectDropdown
                                  intialValue={form?.parentTeam}
                                  placeholder="Select Team"
                                  options={teams}
                                  result={(e) => {
                                    setform({ ...form, parentTeam: e.value });
                                  }}
                                  theme="search"
                                  displayValue="fullName"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              {detail?.parentTeam ? (
                                <>
                                  <div className="w-60 flex-half move-left">
                                    <div className="ui-chip">
                                      <span
                                        onClick={() =>
                                          viewTeam(detail?.parentTeam?.id)
                                        }
                                        className="ui-chip-icon ui-chip-icon-bg pointer"
                                      >
                                        <img
                                          src={methodModel.userImg(
                                            detail?.parentTeam?.image
                                          )}
                                          className="imgperson_op"
                                        />
                                        {detail?.parentTeam?.fullName
                                          .charAt(0)
                                          .toUpperCase() +
                                          detail?.parentTeam?.fullName.slice(1)}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </li>
                        <div className="new-separator-bar"></div>{" "}
                        <li className="d-flex align-items-start">
                          <p className="flex-half">
                            <b>Team Color</b>
                            <br />
                            Choose a color to represent this team visually
                            across the platform, making it easily
                            distinguishable from others.
                          </p>

                          <div className="w-60 flex-half move-left">
                            <input
                              type="color"
                              value={form.color}
                              disabled={!form?.edit}
                              onChange={(e) => {
                                setform({ ...form, color: e.target.value });
                              }}
                            />
                          </div>
                        </li>
                        <div className="new-separator-bar"></div>{" "}
                        <li>
                          <p>
                            <b>Status</b>
                          </p>
                          <div className="w-60 flex-half move-left">
                            <span
                              className={
                                detail?.status == "deactive"
                                  ? "text-capitalize badge badge-danger custom-badge"
                                  : "text-capitalize  badge badge-success custom-badge"
                              }
                            >
                              {detail?.status == "deactive"
                                ? "inactive"
                                : detail?.status}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}

                  {tab == "identity" ? (
                    <>
                      <ul className="detail-view-list">
                        <li className="d-flex align-items-start">
                          <p className="flex-half">
                            <b>Team Mission</b>
                            <br />
                            Define the team’s core purpose and the unique impact
                            it strives to make within the organization.
                          </p>

                          {form?.edit ? (
                            <div
                              className="w-60 flex-half move-left"
                              name="description"
                              valuePropName="data"
                              getValueFromEvent={(event, editor) => {
                                const data = editor.getData();
                                return data;
                              }}
                            >
                              <CKEditor
                                data={form?.description}
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setform({ ...form, description: data });
                                }}
                              />
                            </div>
                          ) : (
                            <div className="w-60 flex-half move-left">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: detail?.description || "--",
                                }}
                              ></span>
                            </div>
                          )}
                        </li>
                        <div className="new-separator-bar"></div>{" "}
                        <li className="d-flex align-items-start">
                          <p className="mb-0 flex-half">
                            <b>Team Vision</b>
                            <br />
                            Describe the team’s long-term aspirations and the
                            future it aims to create.
                          </p>
                          {form?.edit ? (
                            <div
                              className="w-60 flex-half move-left"
                              name="description"
                              valuePropName="data"
                              getValueFromEvent={(event, editor) => {
                                const data = editor.getData();
                                return data;
                              }}
                            >
                              <CKEditor
                                data={form?.vision}
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setform({ ...form, vision: data });
                                }}
                              />
                            </div>
                          ) : (
                            <div className="w-60 flex-half move-left">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: detail?.vision || "--",
                                }}
                              ></span>
                            </div>
                          )}
                        </li>
                        <div className="new-separator-bar"></div>{" "}
                        <li className="flex-column">
                          {tab == "identity" ? (
                            <>
                              <div className="pb-2 align-items-center mb-3 d-flex">
                                <p className="mb-0 flex-half">
                                  <b>Values</b>
                                  <br />
                                  List the guiding principles that shape the
                                  team’s culture and decision-making. Click
                                  'Add' to include each value.
                                </p>

                                <button
                                  type="button"
                                  className="btn btn-primary ml-auto btn-sm "
                                  onClick={() => openValue()}
                                >
                                  <i className="fa fa-plus me-2"></i>Add Value
                                </button>
                              </div>

                              <div className="row">
                                {values.map((item) => {
                                  return (
                                    <>
                                      <div className="col-12 col-sm-12 col-md-6 col-lg-6  col-xl-4 mt-3">
                                        <div className=" sales-sect-bg">
                                          <div className="sales_headings d-flex align-items-center text-capitalize ">
                                            <div className="value-title">
                                              <h6 className="sub_heading mb-0">
                                                {item.title}
                                              </h6>
                                            </div>

                                            <div className="dropdowns_custom value-dropdown">
                                              <div className="dropdown ">
                                                <span
                                                  className=" text-dark dotted"
                                                  type="button"
                                                  data-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <i
                                                    className="fa fa-ellipsis-h text-dark"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <div className="dropdown-menu">
                                                  <span
                                                    className="dropdown-item pointer align-items-center"
                                                    onClick={() => {
                                                      openValue(item);
                                                    }}
                                                  >
                                                    <i className="fa fa-pen pointer me-2"></i>
                                                    Edit
                                                  </span>

                                                  <span
                                                    className="dropdown-item pointer align-items-center"
                                                    onClick={() => {
                                                      deleteValue(item.id);
                                                    }}
                                                  >
                                                    <i className="fa fa-trash text-danger pointer me-2"></i>
                                                    Delete
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="value-parah">
                                            <p className="Amount_sales">
                                              {item.description || "--"}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </li>
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}

                  {form.edit ? (
                    <>
                      <div className="text-right d-flex gap-2 mb-3">
                        <button
                          className="btn btn-secondary ml-auto"
                          type="button"
                          onClick={() => handleCancel()}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={handleUpdate}
                        >
                          Save
                        </button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>

      <button
        type="button"
        id="valuePopupBtn"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#valuePopup"
      ></button>

      <div
        className="modal fade"
        id="valuePopup"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {valueForm.id ? "Edit" : "Add"} Value
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                valueSubmit();
              }}
            >
              <div className="modal-body">
                <div className="mb-3">
                  <label>Title</label>
                  <input
                    type="text"
                    value={valueForm.title}
                    className="form-control"
                    onChange={(e) => {
                      setValueForm({ ...valueForm, title: e.target.value });
                    }}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    value={valueForm.description}
                    onChange={(e) => {
                      setValueForm({
                        ...valueForm,
                        description: e.target.value,
                      });
                    }}
                    maxLength={255}
                    required
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button className="btn btn-primary" disabled={valueLoader}>
                  Save{" "}
                  {valueLoader ? (
                    <>
                      <i className="fa fa-spinner fa-spin"></i>
                    </>
                  ) : (
                    <></>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamDetail;
