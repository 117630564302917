import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login_success, setTabs } from "../../actions/user";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { Link } from "react-router-dom";
import "./style.scss";
import methodModel from "../../methods/methods";
import { ToastContainer, toast } from "react-toastify";
import crypto from "crypto-js";
import environment from "../../environment";

const Login = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push(
        user?.planData?.plan_type == "free"
          ? "/teams"
          : user?.planData?.plan_type == "paid" && user?.planAmountPaid
          ? "/teams"
          : "/plan?modal=" + true
      );
    }
  }, []);

  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [remember, setRemember] = useState(false);
  const [password, setPassword] = useState("");
  const [data, setdata] = useState("");
  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });

  useEffect(() => {
    loader(true);
    setTimeout(() => {
      loader(false);
    }, 500);
    let r = localStorage.getItem("remember");
    if (r) {
      let data = JSON.parse(r);
      setUsername(data.email);
      setPassword(data.password);
      setRemember(true);
    }
    let message = methodModel.getPrams("message");
  }, []);

  const getStriprId_ofPlan = (plan, interval = 1) => {
    if (plan) {
      let pricing = [...plan];
      let stripe_id = "";
      pricing?.filter((itm) => {
        if (itm?.interval_count == interval) {
          stripe_id = itm?.stripe_price_id || "";
        }
      });

      return stripe_id;
    } else {
      return "";
    }
  };

  const Stripe = (stripId, user) => {
    let url = "payment";
    let paymentData = user.planData;
    let interCount = 1;
    const payload = {
      isTrial: "yes",
      plan_id: paymentData?._id || paymentData?.id,
      user_id: user?.id,
      stripe_price_id: stripId || paymentData?.pricing[0]?.stripe_price_id,
      interval_count: interCount || paymentData?.pricing[0]?.interval_count,
      seats: 1,
    };

    loader(true);
    ApiClient.post(url, payload).then((res) => {
      if (res.success) {
        setTimeout(() => {
          toast.success(res?.message);
        }, 100);
        window.location.assign(res?.data?.url);
      }
      loader(false);
    });
  };

  const stipePayment = (plan, user) => {
    Stripe(getStriprId_ofPlan(plan?.pricing), user);
  };

  const AtouLogin = (id) => {
    loader(true);
    ApiClient.post("autoLogin", { id }).then((res) => {
      if (res.success) {
        if (remember) {
          localStorage.setItem("remember", JSON.stringify(data));
        } else {
          localStorage.removeItem("remember");
        }
        localStorage.setItem("token", res.data.access_token);
        dispatch(login_success({ ...res.data, tabs: "dashboard" }));
        setTimeout(() => {
          toast.success(
            `Welcome,${
              res.data?.firstName || res.data?.fullName
            }! 🌟 Ready to turn your goals into achievements? Let's create your first objective and start this exciting journey together. Your success starts now!`
          );
        }, 1000);
        // setTabs("dashboard")
        if (res.data?.planData?.plan_type == "free") {
          let paylod = {
            planType: res.data?.planData?.plan_type,
            paidAmount: 0,
            freePlanBuy: true,
            seats: res?.data?.planData?.numberOfPeople,
            planId: res?.data?.planId,
          };
          ApiClient.post(`subscription`, paylod).then((res) => {
            if (res?.success) {
              console.log("subscrition api hit");
            }
          });
        }

        if (false) {
          // if(res.data?.planData?.plan_type == 'paid'){
          ApiClient.get("profile", { id: res.data.id }).then((pres) => {
            stipePayment(pres.data?.planData, pres.data);
          });
        } else {
          if (!res.data.planId) {
            history.push("/plan");
            return;
          }
          history.push("/dashboard");
        }

        loader(false);
      }
    });
  };

  useEffect(() => {
    let prm = {
      id: methodModel.getPrams("id"),
    };
    if (prm?.id) {
      AtouLogin(prm?.id);
    }
  }, []);

  const enc = (data) => {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      environment.secretKey
    ).toString();
    return ciphertext;
  };

  const hendleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: username,
      password,
    };
    loader(true);

    let payload = {
      data: enc(data),
    };

    ApiClient.post("user/signin", payload).then((res) => {
      if (res.success) {
        if (remember) {
          localStorage.setItem("remember", JSON.stringify(data));
        } else {
          localStorage.removeItem("remember");
        }
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("CreatedAt", true);
        dispatch(login_success(res.data));

        if (false) {
          // if(res.data?.planData?.plan_type == 'paid'&&!res.data.trialUsed){
          ApiClient.get("profile", { id: res.data.id }).then((pres) => {
            stipePayment(pres.data?.planData, pres.data);
          });
        } else {
          if (!res.data.planId) {
            history.push("/plan");
            return;
          }
          if (res?.data?.isEmployee || res?.data?.role == "employee") {
            loader(true);
            history.push("/teams");
            dispatch(setTabs("people"));
            loader(false);
          } else {
            dispatch(setTabs("dashboard"));
            // history.push(res.data?.planData?.plan_type == 'free' ? '/teams' : res.data?.planData?.plan_type == 'paid' && res.data?.planAmountPaid ? '/teams' : '/plan?modal='+true);
            history.push("/dashboard");
          }
        }

        loader(false);
      }
    });
  };

  return (
    <>
      <div className="signIn-main">
        <div className="container">
          <div className="row ">
            <div className="col-md-6 p-0 mx-auto  ">
              <div className="right_side">
                <form className="centerLogin" onSubmit={hendleSubmit}>
                  <div
                    className="logo_set mb-4"
                    onClick={() => history.push("/home")}
                  >
                    <img
                      className="logo_login"
                      src="assets/img/images/footer_logo.png"
                    />
                  </div>

                  <div className="text-center mb-5">
                    <h3 className="text-center lgtext">
                      Log in to your account
                    </h3>
                  </div>

                  <div className="mb-3">
                    <label>Email*</label>
                    <div className="inputWrapper">
                      <input
                        type="email"
                        className="form-control mb-0 bginput"
                        placeholder="Email address"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label>Password*</label>
                    <div className="inputWrapper">
                      <input
                        type={eyes.password ? "text" : "password"}
                        className="form-control mb-0 bginput"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        required
                      />
                      <i
                        className={
                          eyes.password ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                        onClick={() =>
                          setEyes({ ...eyes, password: !eyes.password })
                        }
                      ></i>
                    </div>
                  </div>

                  <div className="forget">
                    <Link to="/forgotpassword" className="text-primary">
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="mt-0">
                    <button
                      type="submit"
                      className="btn btn-primary loginclass mt-2"
                    >
                      Sign in
                    </button>
                  </div>

                  <p className="text-center mt-3 no-account">
                    Don’t have an account?{" "}
                    <span className="d-block">
                      <Link className="sign_up" to="/plan">
                        {" "}
                        Start with free trial
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>
                      </Link>
                    </span>
                  </p>

                  {/* <div className="borderCls mt-5">
                  <span className="or">or</span>
                </div>
                <div className="text-center d-flex justify-content-between mt-5">
                  <a className="btn btn-outline google_id">
                    <img src="/assets/img/gogle.png" />
                    <span className="google_heading">Google</span>
                  </a>
                  <a className="btn btn-outline google_id">
                    <img src="/assets/img/facebooklogo.png" />
                    <span className="google_heading">Facebook</span>
                  </a>
                </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position="top-right" />
    </>
  );
};

export default Login;
