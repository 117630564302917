import React, { useEffect, useState } from "react";
import methodModel from "../../methods/methods";
import ApiClient from "../../methods/api/apiClient";
import { Tooltip } from "antd";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import { GoIssueTrackedBy } from "react-icons/go";
import { GoIssueTracks } from "react-icons/go";
import { CgDanger } from "react-icons/cg";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AsyncSelectDropdown from "../../components/common/AsyncSelectDropdown";

const ModalOKR = ({
  sessionId,
  handleKeyResultAction = () => {},
  getOKRListing = () => {},
  handleUpdateKeyResultModal = () => {},
  calculateProgress = () => {},
  detail,
  handleAction,
  findOKRProgress,
  keyResult,
  setkeyResult,
  owners,
  updateKeyResult,
  setupdateKeyResult,
  clearFields,
  childObjective,
  form,
  setform,
  getData,
  sessions,
  parentKeyResult,
  detailModal,
  childKeyResult,
  setchildKeyResult,
  result = () => {},
  valueData,
  setNewValue = () => {},
  valueDataUnit,
  setNewValueUnit = () => {},
}) => {
  const user = useSelector((state) => state.user);
  const [OKRFilter, setOKRFilter] = useState("keyResult");
  const [disableed, setdisable] = useState(false);
  const [keyChildResultData, setkeyChildResultData] = useState([]);

  const option = (itm) => {
    return itm
      ? {
          label: (
            <div className="d-flex">
              <span className="mr-2 objective-img">
                {/* <LuCircleDotDashed /> */}
                {itm?.keyType == "child_objective" || itm?.keyType == "okr" ? (
                  <span className="objective-img ">
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.1 2.18a9.93 9.93 0 0 1 3.8 0"></path>
                      <path d="M17.6 3.71a9.95 9.95 0 0 1 2.69 2.7"></path>
                      <path d="M21.82 10.1a9.93 9.93 0 0 1 0 3.8"></path>
                      <path d="M20.29 17.6a9.95 9.95 0 0 1-2.7 2.69"></path>
                      <path d="M13.9 21.82a9.94 9.94 0 0 1-3.8 0"></path>
                      <path d="M6.4 20.29a9.95 9.95 0 0 1-2.69-2.7"></path>
                      <path d="M2.18 13.9a9.93 9.93 0 0 1 0-3.8"></path>
                      <path d="M3.71 6.4a9.95 9.95 0 0 1 2.7-2.69"></path>
                      <circle cx="12" cy="12" r="1"></circle>
                    </svg>
                  </span>
                ) : (
                  <span className="key-img">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                      <path d="M17 4h3v16h-3V4zM5 14h3v6H5v-6zm6-5h3v11h-3V9z"></path>
                    </svg>
                  </span>
                )}
              </span>
              <span>{itm.title}</span>
            </div>
          ),
          value: itm._id,
        }
      : null;
  };

  const [filter, setfilter] = useState({
    page: 1,
    count: 10,
    search: "",
    ownerId: "",
  });
  const [summit, setsummit] = useState(false);
  const [data, setdata] = useState([]);
  const [details, setdetail] = useState();

  const handleCreateOKR = (e) => {
    e.preventDefault();
    setdisable(true);
    loader(true);
    let payload = {
      title: form?.title,
      ownerIds: form?.ownerIds || [],
      contributors: form?.contributors || [],
      whyNow: form?.whyNow || "",
      session: form?.session?.value,
      sessionId: form?.session?.Id,
      parentId: form?.parentId || null,
      okrId: form?.parentId,
      keyType: form?.parentId ? "child_objective" : "okr",
      grandParentId: detail?.id || detail?._id,
    };
    if (form?.id) {
      payload = { ...payload, id: form?.id };
      ApiClient.put(`okr`, payload).then((res) => {
        if (res.success) {
          toast.success(res?.message);
          result({ event: "All Submit", value: res.data });
          getData({}, false);
          getOKRListing();
          document.getElementById("CloseOKRsModal").click();
          setdisable(false);
        }
      });
    } else {
      ApiClient.post(`okr`, payload).then((res) => {
        if (res.success) {
          result({ event: "All Submit", value: res.data });
          if (form?.parentId) {
            toast.success("Child OKR Created");
            result({ event: "Child Submit", value: res.data });
          } else {
            // toast.success(res?.message);
            result({ event: "Parent Submit", value: res.data });
            toast.success("Objective created! Let's crush it! 💪");
          }
          getData({}, false);
          getOKRListing();
          document.getElementById("CloseOKRsModal").click();
          setdisable(false);
        }
        loader(false);
      });
    }
  };

  const clearKeyResultModal = () => {
    setkeyResult({
      title: "",
      unit: "not set",
      description: "",
      note: "",
      targetNumber: "",
      initialNumber: "",
      contributors: [],
      whyNow: "",
      deadline: "no custom deadline",
      softDeadline: "",
      hardDeadline: "",
    });
  };

  const handleCreateKeyResult = (e) => {
    e.preventDefault();

    loader(true);
    let payload = {
      ...keyResult,
      ownerIds: keyResult?.ownerIds?.map((item) => item?.value) || [],
      okrId: detail?.id || detail?._id,
      session: keyResult.session || null, // Ensure session is not an empty string
      keyType: "key_result",
    };
    payload.targetNumber = Number(payload?.targetNumber);
    payload.initialNumber = Number(payload?.initialNumber);

    if ((keyResult?._id || keyResult?.id) && !childKeyResult) {
      payload = { ...payload, id: keyResult?._id || keyResult?.id };
      ApiClient.put(`okr`, payload).then((res) => {
        if (res.success) {
          document.getElementById("CloseKeyResultModal").click();
          getData({}, false);
          getOKRListing();
          detailModal(detail?.id || detail?._id, false);
          setOKRFilter("keyResult");
        }
        loader(false);
      });
    } else {
      payload = {
        ...payload,
        keyType: "key_result",
        parentId: childKeyResult || detail?.id || detail?._id || null,
        initialNumber: payload?.initialNumber || 0,
        newValue: payload?.initialNumber || 0,
      };

      ApiClient.post(`okr`, payload).then((res) => {
        if (res.success) {
          getData({}, false);
          getOKRListing();
          setOKRFilter("keyResult");
          detailModal(detail?.id || detail?._id, false);
          toast("New Key Result added! Let's measure success together! 📊", {
            position: "bottom-right",
            autoClose: 4000,
          });
          document.getElementById("CloseKeyResultModal").click();
        }
        loader(false);
      });
    }
  };

  const handleUpdateKeyResult = (e) => {
    e.preventDefault();
    let payload = {
      newValue: updateKeyResult?.newValue,
      confidenceLevel: updateKeyResult?.confidenceLevel,
      note: updateKeyResult?.note,
      dateOfUpdate: updateKeyResult?.dateOfUpdate,
      okrId: updateKeyResult?.okrId || null,
      id: updateKeyResult?.keyResultId,
      goal: updateKeyResult?.goal,
    };

    ApiClient.put(`okr`, payload).then((res) => {
      if (res.success) {
        getData({}, false);
        getOKRListing();
        detailModal(detail?.id || detail?._id, false);
        document.getElementById("CloseUpdateKeyResultModal").click();
        toast(
          payload?.newValue == updateKeyResult?.goal
            ? "Victory! You've nailed a Key Result. 🏆🚀"
            : "Another step closer to success! 👣",
          { position: "bottom-right", autoClose: 4000 }
        );
      }
    });
  };
  useEffect(() => {
    {
      getChildData();
    }
  }, []);

  const findUnitSymbol = (item) => {
    if (item?.unit == "%") return "";
    else {
      if (!item?.unit || item?.unit == "not set") return "";
      else return item?.unit;
    }
  };

  const getChildData = (p = {}, load = true) => {
    let filt = {
      ...filter,

      // sessionId: sessionId || getsessionId,keyType:"okr",
      ...p,
    };
    if (user?.role != "employee") {
      filt = { ...filt, addedBy: user?.id || user?._id };
    }

    loader(true);
    // setLoadingState(true);
    ApiClient.get(`okrs`, filt).then((res) => {
      // setLoadingState(false);
      if (res.success) {
        setdata([...res?.data]);

        // settotal(res?.total);
        // setchild(res?.data.childObjective);
        if (detail?.id || detail?._id) {
          setdetail(
            res?.data?.find(
              (item) => item?._id == detail?.id || item?._id == detail?._id
            )
          );
        }
      }
      loader(false);
    });
  };

  const teamsArray = (type = "") => {
    let arr = owners || [];
    let ownerIds = form.ownerIds || [];
    let contributors = form.contributors || [];
    let exist = [...ownerIds, ...contributors];
    if (type == "ownerIds")
      exist = exist.filter((itm) => !ownerIds.includes(itm));
    if (type == "contributors")
      exist = exist.filter((itm) => !contributors.includes(itm));

    arr = arr.filter((itm) => !exist.includes(itm.id));
    return arr;
  };

  const confidenceLevelClass = (item) => {
    let value = `${
      item?.confidenceLevel == "low" || item?.confidenceLevel == ""
        ? "red-color"
        : item?.confidenceLevel == "medium"
        ? "orange-color"
        : item?.confidenceLevel == "high"
        ? "green-color"
        : ""
    }`;

    if (item?.keyType == "child_objective" || item?.keyType == "okr") {
      value = "";
    }

    return value;
  };

  const keyResultPercent = (item) => {
    return methodModel.keyResultPercent(item);
  };

  return (
    <>
      {/* Full Side Screen Modal */}
      <button
        className="btn btn-primary d-none"
        id="OpenFullSideScreenModal"
        data-toggle="modal"
        data-target="#fullSideScreenModal"
      >
        OKR Detail
      </button>
      <div
        className="modal fade drawer right-align"
        id="fullSideScreenModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="fullSideScreenModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content fullSideScreenModal modal-lg">
            <div className="modal-header align-items-center">
              <h5
                className="modal-title d-flex align-items-center"
                id="fullSideScreenModalLabel"
              >
                <Tooltip title="Objective" placement="top">
                  <span className="mr-2 objective-img">
                    {/* <LuCircleDotDashed /> */}
                    {detail?.keyType == "child_objective" ||
                    detail?.keyType == "okr" ? (
                      <span className="objective-img ">
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10.1 2.18a9.93 9.93 0 0 1 3.8 0"></path>
                          <path d="M17.6 3.71a9.95 9.95 0 0 1 2.69 2.7"></path>
                          <path d="M21.82 10.1a9.93 9.93 0 0 1 0 3.8"></path>
                          <path d="M20.29 17.6a9.95 9.95 0 0 1-2.7 2.69"></path>
                          <path d="M13.9 21.82a9.94 9.94 0 0 1-3.8 0"></path>
                          <path d="M6.4 20.29a9.95 9.95 0 0 1-2.69-2.7"></path>
                          <path d="M2.18 13.9a9.93 9.93 0 0 1 0-3.8"></path>
                          <path d="M3.71 6.4a9.95 9.95 0 0 1 2.7-2.69"></path>
                          <circle cx="12" cy="12" r="1"></circle>
                        </svg>
                      </span>
                    ) : (
                      <span className="key-img">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path fill="none" d="M0 0h24v24H0V0z"></path>
                          <path d="M17 4h3v16h-3V4zM5 14h3v6H5v-6zm6-5h3v11h-3V9z"></path>
                        </svg>
                      </span>
                    )}
                  </span>
                </Tooltip>{" "}
                {detail?.title}
              </h5>
              <div className="d-flex">
                <div className="dropdowns_custom">
                  {user?.role != "employee" && (
                    <div className="dropdown">
                      <span
                        className="text-dark p-2"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i
                          className="fa fa-ellipsis-h text-dark"
                          aria-hidden="true"
                        ></i>
                      </span>
                      <div className="dropdown-menu">
                        <span
                          className="dropdown-item pointer"
                          onClick={(e) => {
                            if (detail.keyType == "child_objective")
                              handleAction("Child Objective", detail);
                            else
                              handleAction("Edit", detail?._id || detail?.id);
                          }}
                        >
                          <i className="fa fa-pen mr-2"></i>
                          Edit
                        </span>
                        <span
                          className="dropdown-item text-danger pointer"
                          onClick={(e) => handleAction("Delete", detail?.id)}
                        >
                          <i className="fa fa-trash mr-2"></i>
                          Delete
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  id="CloseFullSideScreenModal"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-3 my-2">
                      <label className="">Session</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <label className="text-capitalize">
                        {detail?.sessionTitle?.title || detail?.sessionTitle}
                      </label>
                    </div>
                    <div className="col-md-3 my-2">
                      <label className="">Owner</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <label className="">
                        {detail?.ownerDetails?.map((item) => {
                          return (
                            <div className="ui-chip">
                              <span className="ui-chip-icon ui-chip-icon-bg">
                                <img
                                  src={methodModel.userImg(item?.image)}
                                  width=""
                                />
                              </span>
                              <p className="mb-0">
                                {item?.fullName || item?.name}
                              </p>
                            </div>
                          );
                        })}
                      </label>
                    </div>
                    <div className="col-md-3 my-2">
                      <label className="">Contributor</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <label className="">
                        {detail?.contributorDetails?.map((item) => {
                          return (
                            <div className="ui-chip">
                              <span className="ui-chip-icon ui-chip-icon-bg">
                                <img
                                  src={methodModel.userImg(item?.image)}
                                  width=""
                                />
                              </span>
                              <p className="mb-0">
                                {item?.fullName || item?.name}
                              </p>
                            </div>
                          );
                        })}
                      </label>
                    </div>
                    <div className="col-md-3 my-2">
                      <label className="">Why now</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <div className="reasoning-border">
                        <label className="">{detail?.whyNow || "--"}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="overall-progress-main">
                    <h4>Overall Progress</h4>
                    <span className="progressCircle">
                      <CircularProgressbar
                        value={findOKRProgress(
                          detail?.keyResults,
                          "okr from child modal"
                        ).replace("%", "")}
                        text={`${findOKRProgress(
                          detail?.keyResults,
                          "okr from child modal"
                        )}`}
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className="py-2 border-bottom mt-3">
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <h5 className="mb-0">Key Results</h5>
                  <div>
                    {user?.role != "employee" && (
                      <button
                        className="btn btn-primary btn-small"
                        id="OpenKeyResultModal"
                        onClick={() => {
                          setchildKeyResult("");
                          clearKeyResultModal();
                        }}
                        data-toggle="modal"
                        data-target="#KeyResultModal"
                      >
                        <i className="fa fa-plus me-2"></i>Add Key Result
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="">
                <table
                  className="table key-result-table object-tb"
                  valign="middle"
                >
                  <tbody>
                    {detail &&
                      detail?.keyResults?.map((item) => {
                        return (
                          <>
                            <tr>
                              <td
                                scope="row"
                                className="result-title"
                                valign="middle"
                              >
                                <div className="d-flex align-items-center">
                                  <span className="objective-img">
                                    {/* <LuCircleDotDashed /> */}
                                    {item?.keyType == "child_objective" ||
                                    item?.keyType == "okr" ? (
                                      <span className="objective-img ">
                                        <svg
                                          stroke="currentColor"
                                          fill="none"
                                          strokeWidth="2"
                                          viewBox="0 0 24 24"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M10.1 2.18a9.93 9.93 0 0 1 3.8 0"></path>
                                          <path d="M17.6 3.71a9.95 9.95 0 0 1 2.69 2.7"></path>
                                          <path d="M21.82 10.1a9.93 9.93 0 0 1 0 3.8"></path>
                                          <path d="M20.29 17.6a9.95 9.95 0 0 1-2.7 2.69"></path>
                                          <path d="M13.9 21.82a9.94 9.94 0 0 1-3.8 0"></path>
                                          <path d="M6.4 20.29a9.95 9.95 0 0 1-2.69-2.7"></path>
                                          <path d="M2.18 13.9a9.93 9.93 0 0 1 0-3.8"></path>
                                          <path d="M3.71 6.4a9.95 9.95 0 0 1 2.7-2.69"></path>
                                          <circle
                                            cx="12"
                                            cy="12"
                                            r="1"
                                          ></circle>
                                        </svg>
                                      </span>
                                    ) : (
                                      <span className="key-img">
                                        <svg
                                          stroke="currentColor"
                                          fill="currentColor"
                                          strokeWidth="0"
                                          viewBox="0 0 24 24"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill="none"
                                            d="M0 0h24v24H0V0z"
                                          ></path>
                                          <path d="M17 4h3v16h-3V4zM5 14h3v6H5v-6zm6-5h3v11h-3V9z"></path>
                                        </svg>
                                      </span>
                                    )}
                                  </span>
                                  <span
                                    className={`progressDiv mx-2 ${confidenceLevelClass(
                                      item
                                    )}`}
                                  >
                                    {keyResultPercent(item)}%
                                  </span>
                                  <p className="mb-0">{item?.title}</p>
                                </div>

                                {OKRFilter == "keyResult" ? (
                                  <span className="small-label">
                                    Should move to {findUnitSymbol()}
                                    {item?.targetNumber}
                                    {item?.unit == "%" ? "%" : ""}, current
                                    progress at {findUnitSymbol(item)}
                                    {item?.newValue || item?.initialNumber}
                                    {item?.unit == "%" ? "%" : ""}
                                  </span>
                                ) : null}
                              </td>
                              {OKRFilter == "keyResult" ? (
                                <td valign="middle">
                                  <span
                                    className={`p-2 badges-color ${
                                      item?.confidenceLevel == "low" ||
                                      item?.confidenceLevel == ""
                                        ? "red-color"
                                        : item?.confidenceLevel == "medium"
                                        ? "orange-color"
                                        : "green-color"
                                    } text-capitalize`}
                                  >
                                    {item?.confidenceLevel == "low" ? (
                                      <span className="badges-diff">
                                        <svg
                                          stroke="currentColor"
                                          fill="currentColor"
                                          strokeWidth="0"
                                          viewBox="0 0 24 24"
                                          className="me-1"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M2.5 12a9.5 9.5 0 1 1 19 0 .75.75 0 0 0 1.5 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11a.75.75 0 0 0 0-1.5A9.5 9.5 0 0 1 2.5 12Z"></path>
                                          <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2.5 2.75a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm3.75 2.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"></path>
                                        </svg>
                                        OFF TRACK{" "}
                                      </span>
                                    ) : item?.confidenceLevel == "medium" ? (
                                      <span className="badges-diff">
                                        <svg
                                          stroke="currentColor"
                                          fill="none"
                                          strokeWidth="0"
                                          viewBox="0 0 24 24"
                                          className="me-1"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z"
                                            fill="currentColor"
                                          ></path>
                                          <path
                                            d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z"
                                            fill="currentColor"
                                          ></path>
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                        AT RISK
                                      </span>
                                    ) : item?.confidenceLevel == "high" ? (
                                      <span className="badges-diff">
                                        <svg
                                          stroke="currentColor"
                                          fill="currentColor"
                                          strokeWidth="0"
                                          viewBox="0 0 24 24"
                                          className="me-1"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M12 2.5a9.5 9.5 0 1 0 0 19 .75.75 0 0 1 0 1.5C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11a.75.75 0 0 1-1.5 0A9.5 9.5 0 0 0 12 2.5Z"></path>
                                          <path d="m13.759 17.48 3.728 3.314a.308.308 0 0 0 .513-.23V18h4.25a.75.75 0 0 0 0-1.5H18v-2.564a.308.308 0 0 0-.513-.23L13.76 17.02a.308.308 0 0 0 0 .46ZM12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
                                        </svg>
                                        ON TRACK
                                      </span>
                                    ) : (
                                      <span className="badges-diff">
                                        <svg
                                          stroke="currentColor"
                                          fill="currentColor"
                                          strokeWidth="0"
                                          viewBox="0 0 24 24"
                                          className="me-1"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M2.5 12a9.5 9.5 0 1 1 19 0 .75.75 0 0 0 1.5 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11a.75.75 0 0 0 0-1.5A9.5 9.5 0 0 1 2.5 12Z"></path>
                                          <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2.5 2.75a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm3.75 2.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"></path>
                                        </svg>
                                        OFF TRACK
                                      </span>
                                    )}
                                  </span>
                                </td>
                              ) : null}
                              <td valign="middle">
                                <span className="img-width-result">
                                  {item?.ownerDetails?.map((itm) => {
                                    return (
                                      <>
                                        <Tooltip
                                          title={itm?.name}
                                          placement="top"
                                        >
                                          <img
                                            src={methodModel.userImg(
                                              itm?.image
                                            )}
                                            width="25px"
                                          />
                                        </Tooltip>
                                      </>
                                    );
                                  })}
                                </span>
                              </td>
                              <td>
                                <div className="progreebar-main">
                                  <div className="progress">
                                    <div
                                      className="progress-bar progress-bar-striped"
                                      role="progressbar"
                                      style={{
                                        width: `${keyResultPercent(item)}%`,
                                      }}
                                      aria-valuenow="10"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </div>
                              </td>
                              {OKRFilter == "keyResult" ? (
                                <td>
                                  {user?.role != "employee" && (
                                    <div className="text-center">
                                      <button
                                        id="UpdateKeyResultModal201"
                                        className="btn key-btn update-btn text-center"
                                        onClick={(e) => {
                                          setNewValue(
                                            item?.newValue ||
                                              item?.initialNumber
                                          );
                                          setNewValueUnit(item?.unit);
                                          handleUpdateKeyResultModal(item);
                                          clearKeyResultModal();
                                        }}
                                        data-toggle="modal"
                                        data-target="#UpdateKeyResultModal"
                                      >
                                        Check-In
                                      </button>
                                    </div>
                                  )}
                                </td>
                              ) : null}
                              <td>
                                {user?.role != "employee" && (
                                  <div className="dropdowns_custom ">
                                    <div className="dropdown">
                                      <span
                                        className="text-dark"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="fa fa-ellipsis-h text-dark"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <div className="dropdown-menu">
                                        {OKRFilter == "keyResult" ? (
                                          <span
                                            className="dropdown-item pointer"
                                            onClick={(e) =>
                                              handleKeyResultAction(
                                                "Child Objective",
                                                item
                                              )
                                            }
                                          >
                                            <i className="fa fa-plus mr-1"></i>
                                            Add Child Objective
                                          </span>
                                        ) : (
                                          <span
                                            className="dropdown-item pointer"
                                            onClick={(e) =>
                                              handleKeyResultAction(
                                                "Child Key Result",
                                                item
                                              )
                                            }
                                          >
                                            <i className="fa fa-plus mr-1"></i>
                                            Add Key Result
                                          </span>
                                        )}
                                        {OKRFilter == "keyResult" ? (
                                          <span
                                            className="dropdown-item pointer"
                                            onClick={(e) => {
                                              setNewValue(
                                                item?.newValue ||
                                                  item?.initialNumber
                                              );
                                              setNewValueUnit(item?.unit);
                                              handleUpdateKeyResultModal(item);
                                            }}
                                            data-toggle="modal"
                                            data-target="#UpdateKeyResultModal"
                                          >
                                            <i className="fa fa-pen mr-1"></i>
                                            Edit
                                          </span>
                                        ) : null}
                                        <span
                                          className="dropdown-item text-danger pointer"
                                          onClick={(e) =>
                                            handleKeyResultAction(
                                              "Delete",
                                              item?._id || item?.id
                                            )
                                          }
                                        >
                                          <i className="fa fa-trash mr-1"></i>
                                          Delete
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Key Result Modal */}
      <div
        className="modal fade create-key-result"
        id="KeyResultModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="KeyResultModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-title">
              <div className="p-3 d-flex justify-content-between align-items-start">
                <div>
                  <h3 className="modal-title" id="KeyResultModalLabel">
                    {keyResult?._id || keyResult?.id ? "Edit" : "Create"} Key
                    Result
                  </h3>
                  <p className="mb-1">
                    Define a clear and measurable outcome to drive progress
                    toward your objective. Aim for key results that are
                    ambitious yet achievable. (
                    <span className="text-danger">*</span>).
                  </p>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <form onSubmit={handleCreateKeyResult}>
              <div className="modal-body">
                <div className="modal-scroll">
                  <div className="row">
                    <div className="col-md-3 my-2 custom-height bar">
                      <label>
                        Title <span className="text-danger">*</span>
                        {/* <br />
                        <small className="text-muted">
                          Provide a brief, action-oriented title that clearly
                          states the objective of this key result.
                        </small> */}
                      </label>
                    </div>
                    <div className="col-md-9 my-2 custom-height bar">
                      <input
                        type="text"
                        value={keyResult?.title}
                        onChange={(e) =>
                          setkeyResult({
                            ...keyResult,
                            title: e.target.value,
                          })
                        }
                        maxLength="70"
                        className="form-control"
                        placeholder="Example : Increase monthly active users by 20%"
                        required
                      />
                    </div>

                    <div className="col-md-3 my-2 custom-height bar">
                      <label>Unit</label>
                    </div>
                    <div className="col-md-9 my-2 custom-height bar">
                      <select
                        className="form-select"
                        value={keyResult?.unit}
                        onChange={(e) =>
                          setkeyResult({ ...keyResult, unit: e.target.value })
                        }
                      >
                        <option value="">Unit</option>
                        <option value="%">Percentage (%)</option>
                        <option value="€">Euro (€)</option>
                        <option value="$">US Dollar ($)</option>
                        <option value="items_no_unit">Items (No Unit)</option>
                        <option value="score_no_unit">Score (No Unit)</option>
                      </select>
                    </div>

                    <div class="row1 bar">
                      <div class="custom-height d-flex align-items-center">
                        <label class="mr-5">
                          Initial number <span class="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          value={keyResult?.initialNumber}
                          onChange={(e) => {
                            setkeyResult({
                              ...keyResult,
                              initialNumber: e.target.value,
                            });
                          }}
                          className="form-control half-input margin-left"
                          placeholder="Enter initial number"
                        />
                      </div>
                      <div class="custom-height d-flex align-items-center">
                        <label class="ml-5 mr-4">
                          Target number <span class="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          value={keyResult?.targetNumber}
                          onChange={(e) => {
                            setkeyResult({
                              ...keyResult,
                              targetNumber: e.target.value,
                            });
                          }}
                          className="form-control half-input"
                          placeholder="Enter target number"
                        />
                      </div>
                    </div>

                    <div className="col-md-3 my-2 custom-height bar">
                      <label>Owners</label>
                    </div>
                    <div className="col-md-9 my-2 custom-height bar">
                      <div className="multi-select-box">
                        <Select
                          value={keyResult?.ownerIds}
                          isClearable={true}
                          onChange={(e) =>
                            setkeyResult({ ...keyResult, ownerIds: e })
                          }
                          options={owners}
                          className="basic-multi-select"
                          placeholder="Select Owners"
                          isMulti
                        />
                      </div>
                    </div>

                    <div className="col-md-3 my-2 custom-height bar">
                      <label>Target Completion Date</label>
                    </div>
                    <div className="col-md-9 my-2 custom-height bar">
                      <select
                        className="form-select"
                        value={keyResult?.deadline}
                        onChange={(e) =>
                          setkeyResult({
                            ...keyResult,
                            deadline: e.target.value,
                          })
                        }
                      >
                        <option value="no custom deadline">
                          No Target Completion Date
                        </option>
                        <option value="hard deadline">Hard Deadline</option>
                        <option value="hard and soft deadline">
                          Hard & Soft Deadline
                        </option>
                      </select>
                    </div>
                    {keyResult?.deadline != "no custom deadline" ? (
                      <>
                        {keyResult?.deadline == "hard and soft deadline" ? (
                          <>
                            <div className="col-md-3 my-2">
                              <label>Soft deadline</label>
                            </div>
                            <div className="col-md-9 my-2">
                              <DatePicker
                                selected={keyResult?.softDeadline}
                                onChange={(date) =>
                                  setkeyResult({
                                    ...keyResult,
                                    softDeadline: date,
                                  })
                                }
                                className="form-control w-100"
                                dateFormat="dd/MMM/yyyy"
                                placeholderText="Soft deadline"
                                minDate={new Date()}
                              />
                            </div>
                          </>
                        ) : null}
                        <div className="col-md-3 my-2 custom-height">
                          <label>Hard deadline</label>
                        </div>
                        <div className="col-md-9 my-2 custom-height">
                          <DatePicker
                            selected={keyResult?.hardDeadline}
                            onChange={(date) =>
                              setkeyResult({
                                ...keyResult,
                                hardDeadline: date,
                              })
                            }
                            className="form-control w-100"
                            dateFormat="dd/MMM/yyyy"
                            placeholderText="Hard deadline"
                            minDate={keyResult?.softDeadline || new Date()}
                          />
                        </div>
                      </>
                    ) : null}

                    <div className="col-md-3 my-2 ">
                      <label>Description</label>
                    </div>
                    <div className="col-md-9 my-2 custom-height">
                      <textarea
                        value={keyResult?.description}
                        onChange={(e) => {
                          setkeyResult({
                            ...keyResult,
                            description: e.target.value,
                          });
                        }}
                        maxLength="70"
                        className="form-control"
                        placeholder="Enter description"
                        style={{
                          resize: "vertical", // Allow resizing vertically
                          overflow: "auto", // Allow scrollbars when content overflows
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="">
                  <button
                    type="button"
                    id="CloseKeyResultModal"
                    className="btn btn-secondary mr-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={!keyResult?.title || !keyResult?.targetNumber}
                    className="btn btn-primary btn-md ml-2"
                  >
                    {keyResult?._id || keyResult?.id ? "Update" : "Add"} Key
                    Result
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Update Key Result Modal */}
      <div
        className="modal fade"
        id="UpdateKeyResultModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="UpdateKeyResultModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-title">
              <div className="p-3 d-flex justify-content-between align-items-start">
                <div>
                  <h3 className="modal-title" id="UpdateKeyResultModalLabel">
                    Check-In on your progress
                  </h3>
                  <p className="mb-1">
                    You're doing great! Share your updates on this Key Result.
                  </p>
                </div>
                <button
                  type="button"
                  className="close"
                  onClick={(e) => clearFields()}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            {/* <Tooltip
              title={`Objective Progress: ${valueData}%`}
              placement="top"
            >
              <div className="progreebar-main">
                <div
                  className="progress"
                  style={{
                    width: "600px",
                    height: "20px",
                    marginRight: "10px",
                    marginLeft: "17px",
                  }}
                >
                  <div
                    className={`progress-bar ${
                      valueData > 50 ? "bg-success" : "bg-danger"
                    }`}
                    role="progressbar"
                    style={{
                      width: `${valueData}%`,
                    }}
                    aria-valuenow="10"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {valueData}%
                  </div>
                </div>
              </div>
            </Tooltip> */}

            <form onSubmit={handleUpdateKeyResult}>
              <div className="modal-body">
                <div className="">
                  <div className="row">
                    <div className="col-md-3 my-2">
                      <label>Current value </label>
                    </div>
                    <div className="col-md-9 my-2">
                      <label>
                        {findUnitSymbol(updateKeyResult)}
                        {valueData}
                        {valueDataUnit == "%" ? "%" : ""}, Should move to{" "}
                        {findUnitSymbol(updateKeyResult)}
                        {updateKeyResult?.goal}
                        {updateKeyResult?.unit == "%" ? "%" : ""}
                      </label>
                    </div>
                    <div className="col-md-3 my-2">
                      <label>New value </label>
                    </div>
                    <div className="col-md-9 my-2">
                      <input
                        type="number"
                        value={updateKeyResult?.newValue}
                        onChange={(e) =>
                          setupdateKeyResult({
                            ...updateKeyResult,
                            newValue: e.target.value,
                          })
                        }
                        className="form-control"
                        placeholder="Enter new value"
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Confidence level</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <ul className="confidence-btns">
                        <li>
                          <button
                            type="button"
                            className={`btn mr-2 ${
                              updateKeyResult?.confidenceLevel == "high" ||
                              updateKeyResult?.confidenceLevel == ""
                                ? "green-color"
                                : "light-yello-btn"
                            }`}
                            onClick={(e) =>
                              setupdateKeyResult({
                                ...updateKeyResult,
                                confidenceLevel: "high",
                              })
                            }
                          >
                            <GoIssueTrackedBy className="me-2" /> ON TRACK{" "}
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className={`btn ${
                              updateKeyResult?.confidenceLevel == "medium"
                                ? "orange-color"
                                : "light-yello-btn"
                            }`}
                            onClick={(e) =>
                              setupdateKeyResult({
                                ...updateKeyResult,
                                confidenceLevel: "medium",
                              })
                            }
                          >
                            <CgDanger className="me-2" />
                            AT RISK
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className={`btn  ${
                              updateKeyResult?.confidenceLevel == "low"
                                ? "red-color"
                                : "light-yello-btn"
                            }`}
                            onClick={(e) =>
                              setupdateKeyResult({
                                ...updateKeyResult,
                                confidenceLevel: "low",
                              })
                            }
                          >
                            <GoIssueTracks className="me-2" />
                            OFF TRACK{" "}
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Note</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <textarea
                        className="form-control"
                        value={updateKeyResult?.note.replace(
                          /<\/?[^>]+(>|$)/g,
                          ""
                        )}
                        onChange={(e) => {
                          setupdateKeyResult({
                            ...updateKeyResult,
                            note: e.target.value,
                          });
                        }}
                        placeholder="Enter your note here"
                        maxLength="255"
                      />
                    </div>

                    <div className="col-md-3 my-2">
                      <label>Date of update</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <DatePicker
                        selected={updateKeyResult?.dateOfUpdate}
                        onChange={(date) => {
                          setupdateKeyResult({
                            ...updateKeyResult,
                            dateOfUpdate: date,
                          });
                          // setkeyResult({
                          //   ...updateKeyResult,
                          //   dateOfUpdate: date,
                          // })
                        }}
                        className="form-control w-100"
                        dateFormat="dd/MMM/yyyy"
                        placeholderText="Date Of Update"
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="">
                  <button
                    type="button"
                    id="CloseUpdateKeyResultModal"
                    className="btn btn-secondary mr-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={!updateKeyResult?.newValue}
                    className="btn btn-primary btn-md ml-2"
                  >
                    Check-In
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* OKRs Modal */}

      <button
        className="btn btn-primary d-none"
        id="CreateOKRModalOpen"
        data-toggle="modal"
        data-target="#OKRsModal"
      >
        Create OKRs
      </button>
      <div
        className="modal fade"
        id="OKRsModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="OKRsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <form onSubmit={handleCreateOKR}>
            <div className="modal-content">
              <div className="modal-title">
                <div className="p-3 d-flex justify-content-between align-items-start">
                  <div>
                    <h3 className="modal-title " id="ManagerModalLabel">
                      {form?.id ? "Update" : "Create a new"} objective
                    </h3>
                    <p className="mb-1">
                      Capture your goal and set the path for success!
                    </p>
                  </div>
                  <button
                    type="button"
                    className="close"
                    onClick={(e) => clearFields()}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <div className="row">
                    <div class="row">
                      <div class="col-12">
                        <label className="bold-label">
                          Title <span class="text-danger">*</span>
                        </label>
                      </div>
                      <div class="col-12">
                        <input
                          type="text"
                          value={form?.title}
                          onChange={(e) =>
                            setform({ ...form, title: e.target.value })
                          }
                          maxLength="70"
                          class="form-control"
                          placeholder="Enter Objective title"
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <label className="bold-label">
                          Session <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="custom-select-btn">
                          <Select
                            className="text-capitalize"
                            value={form?.session}
                            isClearable={true}
                            onChange={(e) => setform({ ...form, session: e })}
                            options={sessions}
                            placeholder="Select Session"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label className="bold-label">Owner</label>
                      </div>
                      <div className="col-12">
                        <MultiSelectDropdown
                          intialValue={form?.ownerIds}
                          placeholder="Select Owners"
                          options={teamsArray("ownerIds")}
                          result={(e) => {
                            setform({ ...form, ownerIds: e.value });
                          }}
                          displayValue="label"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label className="bold-label">Contributor</label>
                      </div>
                      <div className="col-12">
                        <MultiSelectDropdown
                          intialValue={form?.contributors}
                          placeholder="Select Contributor"
                          options={teamsArray("contributors")}
                          result={(e) => {
                            setform({ ...form, contributors: e.value });
                          }}
                          displayValue="label"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div className="col-12">
                        <label className="bold-label">Why Now ?</label>
                      </div>
                      <div className="col-12">
                        <textarea
                          value={form?.whyNow}
                          onChange={(e) =>
                            setform({ ...form, whyNow: e.target.value })
                          }
                          maxLength="255"
                          className="form-control"
                          placeholder="Explain briefly why it is important to focus on this objective now"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div className="col-12 bold-label my-2">Parent</div>
                      <div className="col-12">
                        <AsyncSelectDropdown
                          api="okrs"
                          option={option}
                          value={form.parent}
                          placeholder={option(childObjective)?.label || ""}
                          filter={{
                            sessionId: sessionId,
                            page: 1,
                            count: 10,
                          }}
                          onChange={(e) => {
                            console.log("Selected data:", e); // Log the selected data for debugging

                            setform({
                              ...form,
                              parentId: e?.value || null,
                              parent: e,
                            });
                          }}
                        />
                        <p className="hintCls">
                          Search OKRs, Key results and Objectives
                        </p>
                      </div>
                    </div>

                    {/* {form?.keyType=='child_objective' ? (
                      <>
                        <div className="col-md-3">Parent</div>
                        <div className="col-md-9">
                          <AsyncSelectDropdown
                          api="okrs"
                          option={option}
                          value={form.parent}
                          placeholder={option(detail?.parentData)?.label||''}
                          filter={{sessionId:sessionId,page:1,count:10}}
                          onChange={(e)=>{
                            setform({ ...form, parentId: e?.value||null,parent:e })
                          }}
                          />
                          
                        </div>
                      </>
                    ) : null} */}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={(e) => clearFields()}
                  id="CloseOKRsModal"
                  className="btn btn-secondary mr-2"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={!form?.session || !form?.title || disableed}
                  className="btn btn-primary btn-md "
                >
                  {form?.id ? "Update" : "Create"} OKR
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ModalOKR;
